<template>
    <div class="card text-center col-md-3 mb-3 fontDarktExotics">
        <a :href="`/detalle/${vehicle._id}`" class="cardDestacados">
            <img v-if="vehicle.frontal" :src="vehicle.frontal" class="card-img-top imgDestacados" alt="...">
            <img v-else src="https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg" class="card-img-top imgExotics rounded-sm" alt="...">
            <div class="card-body">
                <h5 class="card-title cardTitleExotics mb-0" :style="{color:color,'font-size':fontSize}">{{vehicle.name}}</h5>
                <p class="card-text cardPriceExotics mb-0" :style="{color:color,'font-size':fontSize}">{{vehicle.precio | currency}}</p>
            </div>
        </a>
    </div>
</template>

<script>
export default {
  name: 'CardDestacados',
  props: [
    'color',
    'fontSize',
    'vehicle'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
