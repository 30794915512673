import axios from "axios";
import configService from "../../configs/config";

const anioService = {};

anioService.getAnios = () => {
  return axios
    .get(`${configService.apiUrl}/years`)
    .then(res => res.data)
    .catch(error => error);
};



export default anioService;
