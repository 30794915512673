import axios from "axios";
import configService from "../../configs/config";

const loginService = {};
loginService.getToken = () => {
  const login = {
    "email": "support@bswdigital.co",
    "password": "Besweet01"
  }
  return axios
    .post(`${configService.apiUrl}/users/authentication`, login)
    .then(res => res.data)
    .catch(error => error);
};

loginService.getUser = email => {
  return axios
    .get(`${configService.apiUrl}/wp/v2/users/?search=${email}`)
    .then(res => res.data.pop())
    .catch(error => error);
};

export default loginService;
