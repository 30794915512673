<template>
  <div class="menuExotics">
    <nav class="navbar bg-exotics-nav navFont d-none d-lg-block">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img
            src="../assets/LOGO-EXOTICS.png"
            width="115"
            height="44"
            class="d-inline-block align-top"
            alt="Logo Exotics"
            loading="lazy"
          />
        </router-link>
        <div class="navbar-expand-lg">
          <div class="navbar-nav">
            <router-link class="nav-link px-3" to="/">HOME</router-link>
            <router-link class="nav-link px-3" to="/vehicles"
              >VEHÍCULOS</router-link
            >
            <!-- <router-link class="nav-link" to="#">> PROPIEDADES</router-link> -->
            <!-- <router-link class="nav-link px-3" to="/subir-modelo" v-if="auth"
              >PUBLICAR</router-link
            >
            <router-link class="nav-link px-3" to="/publicar" v-else
              >PUBLICAR</router-link
            > -->
            <router-link class="nav-link px-3" to="/financiar"
              >FINANCIAMIENTO</router-link
            >
            <router-link class="nav-link px-3" to="/asesoramos"
              >TE ASESORAMOS</router-link
            >
            
            <router-link v-if="!auth" class="nav-link px-3" to="/publicar"
              >PUBLICAR</router-link
            >
            <router-link v-else class="nav-link px-3" to="/subir-modelo"
              >PUBLICAR</router-link
            >

            <router-link v-if="auth" class="nav-link px-3" to="/profile">PERFIL</router-link>

            <router-link v-if="!auth" class="nav-link px-3" to="/login">LOGIN</router-link>
            <!-- <a v-else class="nav-link px-3" @click="logout()">CERRAR SESIÓN</a> -->
          </div>
        </div>
      </div>
    </nav>
    <div class="navBorder d-none d-lg-block"></div>
    <!-- mobile navigation start -->
    <b-navbar toggleable class="d-lg-none bg-exotics-nav">
      <a
        href="https://wa.me/+573053159933?text=Estoy%20vendiendo%20un%20veh%C3%ADculo%20y%20me%20gustar%C3%ADa%20adquirir%20m%C3%A1s%20informaci%C3%B3n"
        target="_blank"
        class="m-0"

      >
        <img
          src="../assets/WAPP-ICON-EXOTICS.png"
          width="27"
          class="d-inline-block align-top"
          alt="Instagram Exotics"
          loading="lazy"
        />
      </a>
      <b-navbar-brand class="m-0">
        <router-link class="navbar-brand m-0 pl-4" to="/">
          <img
            src="../assets/LOGO-EXOTICS.png"
            width="115"
            height="44"
            class="d-inline-block align-top"
            alt="Logo Exotics"
            loading="lazy"
          />
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="navbar-toggle-collapse fontLightExotics">
        <template v-slot:default="{ expanded }">
          <b-icon v-if="expanded" icon="list" class="fontLightExotics"></b-icon>
          <b-icon v-else icon="list" class="fontLightExotics"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto fontLightExotics text-left">
          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/"
            >HOME</router-link
          >
          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/vehicles"
            >VEHÍCULOS</router-link
          >
          <!-- <router-link class="nav-link fontLightExotics borderBottom fontWeight8" to="#">> PROPIEDADES</router-link> -->
          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/publicar"
            v-if="!auth" 
            >PUBLICAR</router-link
          >
          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/subir-modelo"
            v-else
            >PUBLICAR</router-link
          >

          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/financiar"
            >FINANCIAMIENTO</router-link
          >
          <router-link
            class="nav-link fontLightExotics borderBottom fontWeight8"
            to="/asesoramos"
            >TE ASESORAMOS</router-link
          >
          <router-link 
            v-if="auth" 
            class="nav-link fontLightExotics borderBottom fontWeight8" 
            to="/profile"
            >PERFIL</router-link>

          <router-link v-if="!auth" class="nav-link fontLightExotics ontWeight8" to="/login"
            >LOGIN</router-link
          >
          <!-- <a v-else class="nav-link fontLightExotics ontWeight8" @click="logout()" 
            >CERRAR SESIÓN</a
          > -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="navBorder d-lg-none"></div>
  </div>
</template>

<script>
import loginService from "../services/login"
export default {
  name: "Menu",
  data() {
    return {
      auth: false,
    }
  },
  mounted() {
    this.login()
    const user = window.localStorage.getItem("SET_USER")
    if (user &&   user != '') {
      this.auth = true
    } else {
      this.auth = false
    }
  },
  methods: {
    login() {
      loginService.getToken().then((res) => {
        window.localStorage.setItem("AUTH_TOKEN", JSON.stringify(res.data))
      })
    },
    logout() {
      window.localStorage.removeItem('SET_USER')
      window.localStorage.removeItem('AUTH_TOKEN')
      this.$router.push('/')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
