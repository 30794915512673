<template>
    <div class="card cardExotics text-left col-6 mb-3 fontDarktExotics">
        <a :href="`/detalle/${data._id}`">
            <div class="imgExoticsContainerM">
                <div v-if="data.estado === 'nuevo'" class="badgeNewM">
                    <p class="mb-0 fontLightExotics kmExoticsM"><span class="d-md-block d-none">NUEVO</span> 0 Kms</p>
                </div>
                <img v-if="data.frontal" :src="data.frontal" class="card-img-top imgExoticsM" alt="...">
                <img v-else src="https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg" class="card-img-top imgExoticsM" alt="...">
            </div>
            <div class="py-3 card-body">
                <h5 class="card-title cardTitleExoticsM mb-0">{{data.name}}</h5>
                <p class="card-text cardPriceExoticsM mb-0">{{data.precio | currency}} COP</p>
                <p class="mb-1">
                    <span class="modelExoticsM">{{data.anio}}  / </span><span class="kmExoticsM">{{data.kilometraje}} Km  / </span> <span class="kmExotics text-capitalcase">{{data.ubicacion}}</span>
                </p>
                <p class="mb-0 mt-2 kmExotics font-weight-normal" style="color: #9e9e9e">{{ data.concesionario}}</p>
                <!-- <b-button class="btn btn-primary btnPrimaryExotics mt-2" :href="`/detalle/${data.id}`">ver más > </b-button> -->
            </div>
        </a>
    </div>
</template>

<script>
export default {
  name: 'CardM',
  props: [
      'data'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.text-capitalcase{
    text-transform: capitalize;
}
</style>
