<template>
    <div>
        <!-- Desktop version -->
        <div class="footerExotics d-none d-lg-block">
            <div class="navBorder"></div>
            <div class="container py-5">
                <div class="row">
                    <div class="col-2 text-center fontLightExotics">
                        <P class="subTitle">SÍGUENOS EN:</P>
                        <div class="row pt-3">
                            <div class="col-6 logoFooter">
                                <a href="/">
                                    <img src="../assets/logoFooter.png" width="37" class="d-inline-block align-top" alt="Logo Exotics" loading="lazy">
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="https://www.instagram.com/exoticsco_autos/" target="_blank">
                                    <img src="../assets/instagramFooter.png" width="37" class="d-inline-block align-top" alt="Instagram Exotics" loading="lazy">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 text-left">
                        <p class="subTitle fontBlueExotics">CONTÁCTANOS:</p>
                        <p class="footerText">
                            <a class="small-icon-wsp" href="https://wa.me/+573053159933?text=Estoy%20vendiendo%20un%20veh%C3%ADculo%20y%20me%20gustar%C3%ADa%20adquirir%20m%C3%A1s%20informaci%C3%B3n" target="_blank">
                                <img src="../assets/WAPP-ICON-EXOTICS.png" width="13" class="d-inline-block align-middle" alt="Whatsapp Exotics" loading="lazy"/>
                                <span>(+57) 305-315-9933 </span> 
                            </a> 
                            <a class="small-icon-wsp" href="mailto:autos@exoticscolombia.com" target="_blank"> autos@exoticscolombia.com</a> 
                            <a class="small-icon-wsp" href="https://wa.me/+573053159933?text=Estoy%20vendiendo%20un%20veh%C3%ADculo%20y%20me%20gustar%C3%ADa%20adquirir%20m%C3%A1s%20informaci%C3%B3n" target="_blank"> CONTACTANOS</a> 
                        </p>
                    </div>
                    <div class="col-2 text-left">
                        <ul class="navFooter">
                            <li><a class="nav-link" href="/"><span class="fontBlueExotics"> > </span>HOME</a></li>
                            <li><a class="nav-link" href="/vehicles"><span class="fontBlueExotics"> > </span>VEHÍCULOS</a></li>
                            <!-- <li><a class="nav-link" href="#"><span class="fontBlueExotics"> > </span>PROPIEDADES</a></li> -->
                            <li ><a class="nav-link" :href="`${auth? '/subir-modelo': '/publicar'}`"><span class="fontBlueExotics"> > </span>PUBLICAR</a></li>
                        </ul>
                    </div>
                    <div class="col-2 text-left">
                        <ul class="navFooter">
                            <li><a class="nav-link" href="/financiar"><span class="fontBlueExotics"> > </span>FINANCIAMIENTO</a></li>
                            <li><a class="nav-link" href="/asesoramos"><span class="fontBlueExotics"> > </span>TE ASESORAMOS</a></li>
                            <li><a class="nav-link" href="/login"><span class="fontBlueExotics"> > </span>LOGIN</a></li>
                        </ul>
                    </div>
                    <div class="col-4 text-left fontLightExotics">
                        <P class="subTitle">SUSCRÍBETE</P>
                        <div class="input-group mb-3">
                          <input
                            v-model="email"
                            type="text"
                            class="form-control outlineInput"
                            placeholder="E-MAIL"
                            aria-label="E-mail"
                            aria-describedby="button-addon2"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary subscribeBtnInput"
                              type="button"
                              id="button-addon2"
                              @click="addContact()"
                            >
                              Enviar
                            </button>
                          
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <div class="footerExotics d-lg-none">
      <div class="navBorder"></div>
      <div class="container py-3">
        <div class="row">
          <div class="col-5 text-center fontLightExotics">
            <div class="row">
              <div class="col-3 pt-2">
                <a href="/">
                  <img
                    src="../assets/logoFooter.png"
                    width="20"
                    class="d-inline-block align-top"
                    alt="Logo Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="col-3 pt-2">
                <a
                  href="https://www.instagram.com/exoticsco_autos/"
                  target="_blank"
                >
                  <img
                    src="../assets/instagramFooter.png"
                    width="20"
                    class="d-inline-block align-top"
                    alt="Instagram Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="col-6 pt-2">
                <P class="subTitle subTitleM">COLOMBIA</P>
              </div>
            </div>
          </div>
          <div class="col-7 text-left fontLightExotics">
            <div class="input-group">
              <input
                type="text"
                class="form-control outlineInput"
                placeholder="E-MAIL"
                aria-label="E-mail"
                aria-describedby="button-addon2"
                v-model="email"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary subscribeBtnInput"
                  type="button"
                  @click="addContact()"
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailService from '@/services/Email'
export default {
  name: "Footer",
    data() {
      return {
        email: "",
        auth: false
      }
  },
  mounted() {
    const user = window.localStorage.getItem("SET_USER")
    if (user &&   user != '') {
      this.auth = true
    } else {
      this.auth = false
    }
  },
  methods: {
    async addContact() {
      console.log(this.email)
      await EmailService.addContact(this.email)
      .then(res => {
        this.email = ""
        this.$notify.success({
          title: 'Suscripción',
          message: '¡Has sido suscrito a nuetro boletín!',
          offset: 100
        });
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.small-icon-wsp{
    margin-bottom: 5px;
    display: inherit;
    color:  #ffffff;
}

.small-icon-wsp:hover{
    color:  #ffffff;
}
.small-icon-wsp img{
    margin-right: 5px;
}
.small-icon-wsp span{
    border-bottom: 1px solid #ffffff;
}
</style>
