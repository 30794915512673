<template>
    <div class="fichaTabs mt-5">
        <b-tabs content-class="mt-3">
            <b-tab title="Descripción" class="tabTitle" active>
                <div class="fontLightExotics text-left">
                    <p class="description bodyText">
                    {{ data.description }}
                    </p>
                </div>
                <div class="row fontLightExotics text-left">
                    <div class="col-6">
                        <div class="marcaDetalle py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Marca</strong> <p>{{ data.marca | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Modelo</strong> <p>{{ data.modelo | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Placa</strong> <p>{{ data.placa | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Velocidades</strong> <p>{{ data.numero_de_velocidades | Capitalize}}</p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row fontLightExotics text-left">
                    <div class="col-12">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Garantía</strong> 
                                <p v-if="data.garantia">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Versión</strong> <p>{{ data.version | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Ficha Técnica" class="tabTitle">
                <div class="row fontLightExotics text-left">
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Combustible</strong> <p class="text-capitalize">{{ data.tipo_de_motor | Capitalize }}</p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Transmisión</strong> <p>{{ data.transmision | Capitalize }}</p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Híbrido</strong> 
                                <p v-if="data.hibrido">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Motor</strong> <p>{{ data.motor | Capitalize }} </p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Potencia</strong> <p>{{ data.potencia | Capitalize }}</p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Eléctrico</strong> 
                                <p v-if="data.electrico">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Tipo vehículo</strong> <p>{{ data.tipo_de_vehiculo[1].name | Capitalize }}</p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Velocidad Max</strong> <p>{{ data.velocidad_maxima | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Carrocería</strong> <p>{{ data.tipo_de_vehiculo[2].name | Capitalize }}</p>
                            </p>
                        </div>
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>0/100km</strong> <p>{{ data.km100 | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Seguridad" class="tabTitle">
                <div class="row fontLightExotics text-left">
                    <div class="col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Blindaje</strong> 
                                <p v-if="data.blindaje">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import vehiclesService from "@/services/vehicles"

export default {
  name: 'FichaTabsM',
  props: [
    'data'
  ],
  data (){
      return{
          pill: false,
          hide: false,
          data: ''
      }
  },
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/gi, " ")
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getVehicle(this.id)
  },
  methods: {
    showTabs (){
        this.pill=true
        this.hide=true  
    },
    getVehicle(id) {
        vehiclesService.getVehicle(id).then((data) => {
        this.data = data.data
      })
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
