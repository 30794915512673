import axios from "axios";
import configService from "../../configs/config";

const vehicleService = {};

vehicleService.getVehicles = (limit) => {
  console.log("ejecutar")
  if(!limit) limit = 0
  return axios
    .get(`${configService.apiUrl}/vehicles`, {
      params: {
        limit: limit,
        approved: true
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => error);
};

vehicleService.getVehiclesByConcesionario = (concesionario) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/concesionario`, {
      params: {
        concesionario
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => error);
};

vehicleService.orderByAnio = (order) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/anio`, {
      params: {
        order
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => error);
};

vehicleService.orderByPrice = (order) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/price`, {
      params: {
        order
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => error);
};

vehicleService.getVehiclesByFilters = (params) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/byFilter`, {
      params: {
        query: params
      }
    })
    .then(res => {
      console.log(res.data)
      return res.data
    })
    .catch(error => error);
};
vehicleService.search = (text) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/search?s=${text}`)
    .then(res => {
      return res.data
    })
    .catch(error => error);
};
vehicleService.getVehicle = (id,token) => {
  return axios
    .get(`${configService.apiUrl}/vehicles/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
    })
    .then(res => res.data)
    .catch(error => error);
};
vehicleService.crearVehicle = (data,token) => {
  return axios
  .post(`${configService.apiUrl}/vehicles`,
  data,{
    headers: {
        Authorization: `Bearer ${token}`
        }
    })
    .then(res => {
      return res.data
    })
    .catch(error => error);
};
vehicleService.editarVehicle = (id,data,token) => {
  return axios
    .put(`${configService.apiUrl}/vehicles/${id}`,
    data,{
        headers: {
            Authorization: `Bearer ${token}`
            }
        })
    .then(res => res.data)
    .catch(error => error);
};
vehicleService.eliminarVehicle = (id,token) => {
  return axios
    .delete(`${configService.apiUrl}/vehicles/${id}`,
    {
        headers: {
            Authorization: `Bearer ${token}`
            }
        })
    .then(res => res.data)
    .catch(error => error);
};

export default vehicleService;
