import axios from "axios"
import configService from "../../configs/config"

const marcasService = {}

marcasService.getMarcas = async (limit = 0) => {
  const marcas = await axios
    .get(`${configService.apiUrl}/marcas`)
  return marcas.data
}

marcasService.getMarca = async (id) => {
  const marcas = await axios
    .get(`${configService.apiUrl}/marcas/${id}`)
  return marcas.data
}

export default marcasService
