<template>
  <div>
    <!-- desktop version -->
    <div class="d-none d-lg-block">
      <div class="container py-5">
        <h4 class="h3Exotics text-left pb-4">+ DESTACADOS</h4>
        <div class="row">
          <CardDestacados
            :vehicle="vehicle"
            v-for="vehicle in vehicles"
          ></CardDestacados>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <b-button
              class="btn btn-primary btnPrimaryExotics mt-5"
              href="/vehicles"
              >VER CATÁLOGO COMPLETO</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <div class="d-lg-none">
      <div class="container py-5">
        <h4 class="h3ExoticsMobile text-left pb-3">+ DESTACADOS</h4>
        <div class="row destacadosMobile">
          <!-- <CardDestacadosM
            :data="vehicle"
            v-for="vehicle in vehicles"
            class="cardDestacadosMobile"
            fontSize="12px"
          ></CardDestacadosM> -->
        </div>
        <div class="row">
          <div class="col-12">
            <b-button
              class="btn btn-primary btnPrimaryExotics fullWidthExotics"
              href="/vehicles"
              >VER CATÁLOGO COMPLETO</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardDestacados from "../components/CardDestacados.vue"
import vehiclesService from "../services/vehicles"

export default {
  name: "Destacados",
  components: {
    CardDestacados,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      vehicles: [],
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    getVehicles(limit) {
        vehiclesService.getVehicles(limit)
        .then(data => {
            this.vehicles = data.data
        })
    }
  },
  mounted() {
    this.getVehicles(4)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
