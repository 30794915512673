<template>
    <div class="resultsList mb-5 text-center">
      <paginate v-if="!notFound" name="vehicles" :list="vehicles" :per="21" class="row p-0 text-left">
        <Card :data="vehicle" v-for="vehicle in paginated('vehicles')" v-if="gridView" class="d-none d-lg-block"></Card>
        <CardM :data="vehicle" v-for="vehicle in paginated('vehicles')" v-if="gridView" class="d-lg-none"></CardM>
        <div class="d-none d-lg-block fullWidthExotics">
          <CardList :data="vehicle" v-for="vehicle in paginated('vehicles')" v-if="listView" class=""></CardList>
        </div>
        <CardListM :data="vehicle" v-for="vehicle in paginated('vehicles')" v-if="listView" class="d-lg-none"></CardListM>
      </paginate>
      <paginate v-else name="vehicles" :list="vehicles" :per="21" class="row p-0 text-left">
        <p>No hay vehículos</p>
      </paginate>
      <paginate-links
        v-if="!notFound"
        for="vehicles"
        :classes="{ ul: 'flat-pagination' }"
        :async="true"
        :show-step-links="true"
        :step-links="{ prev: 'Anterior <', next: '> Siguiente' }"
        :limit="-1"
        @change="toTop"
      ></paginate-links>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardM from '../components/CardM.vue'
import CardList from '../components/CardList.vue'
import CardListM from '../components/CardListM.vue'
import vehiclesService from '@/services/vehicles'
export default {
  name: 'ResultsList',
  props: [
    'gridView',
    'listView',
    'vehicles'
  ],
  components: {
    Card,
    CardM,
    CardList,
    CardListM
  },
  data() {
      return {
        notFound: false,
        paginate: ["vehicles"]
      }
  },
  mounted() {
      // this.getVehicles(100)
    // this.word = window.localStorage.getItem('search')
    // if(this.word){
    //   this.search(this.word)
    // }else{
    //   this.getVehicles(100)
    // }
  },
  methods: {
    // getVehicles(limit) {
    //     vehiclesService.getVehicles(limit)
    //     .then(vehicles => {
    //         this.vehicles = vehicles.data
    //         this.filterVehiclesByMarca(this.vehicles)
    //     })
    // },
    // filterVehiclesByMarca(dataV) {
    //     dataV.forEach(element => {
    //         if(element.acf.transmision == 'Automático') {
    //             this.vehicles = []
    //             this.vehicles.push(element)
    //         }
    //     })
    //     return this.vehicles
    // },
    // search(word) {
    //     vehiclesService.search(word)
    //     .then(vehicles => {
    //         this.vehiclesCount = vehicles.length
    //         vehicles.forEach(vehi => {
    //           vehiclesService.getVehicle(vehi.id)
    //           .then(data => {
    //             this.vehicles.push(data)
    //           })
    //         });
    //         return this.vehicles
    //     })
    // },
    toTop() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      document.body.scrollTop = 350; // For Safari
      document.documentElement.scrollTop = 350;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
