<template>
  <div class="results">
    <Menu></Menu>
    <BusquedaResultados></BusquedaResultados>
    <!-- desktop version resultados and filter -->
    <div class="resultsContainer d-none d-lg-block">
      <div class="container">
        <div class="row">
          <div class="col-3 text-left pt-5 pr-3">
            <div class="brandSearchedExotics">
              <h3 class="h3Exotics">{{ word }}</h3>
              <p>{{ vehiclesCount }} resultados</p>
            </div>
            <div class="my-5">
              <button
                class="btn btn-primary aplicarFiltro"
                @click="getVehiclesByFilters()"
              >
                Aplicar Filtros
              </button>
              <button
                class="btn btn-primary limpiarFiltros"
                @click="getVehicles()"
              >
                Limpiar Filtros
              </button>
            </div>
            <div class="filtersExotics fontDarktExotics">
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Estado</p>
                <b-form-radio-group
                  v-model="formData.estado"
                  :options="optionsEstados"
                  class="mb-3 fontDarktExotics"
                  value-field="value"
                  text-field="label"
                ></b-form-radio-group>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Marca</p>
                <el-select
                  v-model="formData.marca"
                  @change="getMarca()"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Marca"
                >
                  <el-option
                    v-for="item in optionsMarcas"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Modelo</p>
                <el-select
                  v-model="formData.modelo"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Modelo"
                >
                  <el-option
                    v-for="item in optionsModelos"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Ubicación</p>
                <el-select
                  v-model="formData.ubicacion"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Ubicación"
                >
                  <el-option
                    v-for="item in optionsUbicacion"
                    :key="item.id"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="priceFilterExotics mt-5">
                <p class="filterTitle mb-2">PRECIO</p>
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <el-select
                        v-model="formData.price"
                        filterable
                        class="fullWidthExotics exoticsInput"
                        placeholder="Rango de precio"
                      >
                        <el-option
                          v-for="item in optionsPrice"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Año</p>
                <div class="row">
                  <div class="col exoticsInput">
                    <el-select
                      v-model="formData.low_anio"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Año Min."
                    >
                      <el-option
                        v-for="item in optionsAnios"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="col exoticsInput">
                    <el-select
                      v-model="formData.high_anio"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Año Max."
                    >
                      <el-option
                        v-for="item in optionsAnios"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="kmFilterExotics mt-5">
                <p class="filterTitle mb-2">KILOMETRAJE</p>
                <div class="yearSelectExotics mt-3">
                  <form>
                    <div class="row">
                      <div class="col-md-12">
                        <el-select
                          v-model="formData.kilometraje"
                          filterable
                          class="fullWidthExotics exoticsInput"
                          placeholder="Rango de kilómetros"
                        >
                          <el-option
                            v-for="item in optionsKm"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">
                  Tipo de vehículo
                </p>
                <el-select
                  v-model="formData.tipo_de_vehiculo"
                  @change="getVehicleType()"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Tipo de Vehículo"
                >
                  <el-option
                    v-for="item in optionsTipoVehicle"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Carrocería</p>
                <el-select
                  v-model="formData.carroceria"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Carrocería"
                >
                  <el-option
                    v-for="item in optionsCarroceria"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Transmisión</p>
                <el-select
                  v-model="formData.transmision"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Transmisión"
                >
                  <el-option
                    v-for="item in optionsTransmision"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">
                  Tipo de motor
                </p>
                <el-select
                  v-model="formData.tipo_de_motor"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Tipo de motor"
                >
                  <el-option
                    v-for="(item, index) in optionsTipoMotor"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="mt-5">
                <p for="" class="form-control-label filterTitle">Placa</p>
                <el-select
                  v-model="formData.placas"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Placa"
                >
                  <el-option
                    v-for="(item, index) in optionsPlacas"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="my-5">
                <p class="filterTitle">CONCESIONARIOS</p>
                <el-select
                  class="fullWidthExotics exoticsInput"
                  v-model="formData.concesionario"
                  placeholder="Concesionario"
                >
                  <el-option
                    v-for="(item, index) in optionsConcesionario"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="my-5 pb-5">
                <button
                  class="btn btn-primary aplicarFiltro"
                  @click="getVehiclesByFilters()"
                >
                  Aplicar Filtros
                </button>
              </div>
            </div>
          </div>
          <div class="col-9 text-left pt-5 pl-5 resultListContainer">
            <div class="actionsExtotics mb-5">
              <el-select
                v-model="order"
                placeholder="Ordenar Por"
                class="orderBy mr-3"
                @change="orderBy(order)"
              >
                <el-option
                  v-for="item in orderByOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <a href="" @click="showGrid"
                ><i
                  class="material-icons align-middle gridViewIcon iconDefault mr-2"
                  :class="{ iconActive: isActiveGrid }"
                  >view_module</i
                ></a
              >
              <a href="" @click="showList"
                ><i
                  class="material-icons align-middle listViewIcon iconDefault"
                  :class="{ iconActive: isActiveList }"
                  >view_stream</i
                ></a
              >
            </div>
            <div v-if="vehicles.length > 0 && vehicles">
              <ResultsList
                :vehicles="vehicles"
                :gridView="gridView"
                :listView="listView"
              ></ResultsList>
            </div> 
            <div v-else class="mt-4 mb-4">
              No hay vehiculos para mostrar
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version resultados and filter -->
    <div class="resultsContainerMobile d-lg-none">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12 text-left pt-5 pr-5">
                        <div class="brandSearchedExotics">
                            <h3 class="h3Exotics">MERCEDES</h3>
                            <p>{{vehiclesCount}} resultados</p>
                        </div>
                        <div class="filtersExotics fontDarktExotics">
                            <div class="sortExotics mt-5">
                                <p class="filterTitle mb-2">ordenar las publicaciones</p>
                                <p class="mb-0"><a href="" class="filterItem">Más relevantes</a></p>
                            </div>
                            <div class="concesionarioFilterExotics mt-5">
                                <p class="filterTitle mb-2">CONCESIONARIOS ALIADOS</p>
                                <p class="mb-0"><a href="" class="filterItem">Red Autos (8)</a></p>
                                <p class="mb-0"><a href="" class="filterItem">Pistelli (10)</a></p>
                            </div>
                            <div class="ubicacionFilterExotics mt-5">
                                <p class="filterTitle mb-2">UBICACIÓN</p>
                                <p class="mb-0"><a href="" class="filterItem">Bogotá (2)</a></p>
                                <p class="mb-0"><a href="" class="filterItem">Medellín (10)</a></p>
                                <p class="mb-0"><a href="" class="filterItem">Cali (3)</a></p>
                                <p class="mb-0"><a href="" class="filterItem">Bucaramanga (1)</a></p>
                            </div>
                            <div class="modelFilterExotics mt-5">
                                <p class="filterTitle mb-2">MODELO</p>
                                <p class="mb-0"><a href="" class="filterItem">Q5</a></p>
                                <p class="mb-0"><a href="" class="filterItem">A4</a></p>
                                <p class="mb-0"><a href="" class="filterItem">A3</a></p>
                                <p class="mb-0"><a href="" class="filterItem">A6</a></p>
                            </div>
                            <div class="placaFilterExotics mt-5">
                                <p class="filterTitle mb-2">ÚLTIMO DÍGITO DE LA PLACA</p>
                                <div class="row">
                                    <div class="col-4">
                                        <p class="mb-0"><a href="" class="filterItem">0</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">1</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">2</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">3</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">4</a></p>
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0"><a href="" class="filterItem">5</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">6</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">7</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">8</a></p>
                                        <p class="mb-0"><a href="" class="filterItem">9</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="yearFilterExotics mt-5">
                                <p class="filterTitle mb-2">AÑO</p>
                                <p class="mb-0"><a href="" class="filterItem">2020</a></p>
                                <p class="mb-0"><a href="" class="filterItem">2019</a></p>
                                <p class="mb-0"><a href="" class="filterItem">2018</a></p>
                                <p class="mb-0"><a href="" class="filterItem">2017</a></p>
                                <p class="mb-0"><a href="" class="filterItem">> Ver todo</a></p>
                                <div class="yearSelectExotics mt-3">
                                    <button type="button" class="btn btn-outline-secondary btnOutSecExotics dropdown-toggle mr-3">Desde</button>
                                    <button type="button" class="btn btn-outline-secondary btnOutSecExotics dropdown-toggle mr-3">Desde</button>
                                </div>
                            </div>
                            <div class="priceFilterExotics mt-5">
                                <p class="filterTitle mb-2">PRECIO</p>
                                <form>
                                    <div class="row">
                                        <div class="col">
                                        <input type="text" class="form-control" placeholder="Mínimo">
                                        </div>
                                        <div class="col">
                                        <input type="text" class="form-control" placeholder="Máximo">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="estadoFilterExotics mt-5 mb-5">
                                <p class="filterTitle mb-2">MODELO</p>
                                <p class="mb-0"><a href="" class="filterItem">Nuevo</a></p>
                                <p class="mb-0"><a href="" class="filterItem">Usado</a></p>
                            </div>

                        </div>
                    </div> -->
          <div class="col-12 text-left pt-5 resultListContainer">
            <div class="actionsExtotics mb-5">
              <div class="row">
                <div class="col-8">
                  <el-select
                    v-model="order"
                    placeholder="Ordenar Por"
                    class="orderBy mr-3"
                    @change="orderBy(order)"
                  >
                    <el-option
                      v-for="item in orderByOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-4">
                  <a href="" @click="showGrid"
                    ><i
                      class="material-icons align-middle gridViewIcon iconDefault mr-2"
                      :class="{ iconActive: isActiveGrid }"
                      >view_module</i
                    ></a
                  >
                  <a href="" @click="showList"
                    ><i
                      class="material-icons align-middle listViewIcon iconDefault"
                      :class="{ iconActive: isActiveList }"
                      >view_stream</i
                    ></a
                  >
                </div>
              </div>
            </div>
            <div v-if="vehicles.length >= 0" class="">
              <ResultsList
                :vehicles="vehicles"
                :gridView="gridView"
                :listView="listView"
              ></ResultsList>
            </div>
            <div v-else class="mt-4 mb-4">
              No hay vehiculos para mostrar
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ctaFooterExotics d-none d-lg-block">
      <div class="container py-5">
        <div class="row py-3">
          <div class="col-6 text-left pt-5 pr-5">
            <h2 class="TitleCtaFooterExotics">
              TE ASESORAMOS <br />
              PARA BUSCAR LA MEJOR FORMA <br />
              DE REALIZAR TU SUEÑO
            </h2>
            <a href="/asesoramos">
              <button
                class="btn btn-primary btnPrimaryExotics mt-2 mb-5"
                type="submit"
              >
                contáctanos
              </button>
            </a>
          </div>
          <div class="col-6 text-right">
            <div class="financiarThumContainer">
              <a href="/financiar">
                <img
                  src="../assets/financiar-thumb-back-exotics.png"
                  width="400"
                  class="d-inline-block align-top"
                  alt="Financiar Exotics"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AliadosM></AliadosM>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// @ is an alias to /src
import Card from "../components/Card.vue"
import CardList from "../components/CardList.vue"
import Menu from "../components/Menu.vue"
import BusquedaResultados from "../components/SearchEngine/BusquedaResultados.vue"
import ResultsList from "../components/ResultsList.vue"
import Footer from "@/components/Footer.vue"
import vehicleService from "../services/vehicles"
import marcaService from "../services/marcas"
import aniosService from "../services/Anios"
import ubicacionService from "../services/Ubicacion"
import concesionarioService from "../services/Concesionarios";
import AliadosM from "../components/AliadosM.vue"

export default {
  name: "Results",
  components: {
    Card,
    Menu,
    BusquedaResultados,
    ResultsList,
    CardList,
    Footer,
    AliadosM,
  },
  data() {
    return {
      isActiveGrid: true,
      isActiveList: false,
      vehiclesCount: 0,
      word: "",
      searchData: "",
      gridView: "",
      listView: "",
      high_km: "",
      low_km: "",
      high_price: "",
      price: "",
      concesionariosOptions: [],
      order: "",
      orderByOptions: [
        {
          label: "AÑO MAYOR A MENOR",
          value: "year-desc",
        },
        {
          label: "AÑO MENOR A MAYOR",
          value: "year-asc",
        },
        {
          label: "PRECIO MAYOR A MENOR",
          value: "price-desc",
        },
        {
          label: "PRECIO MENOR A MAYOR",
          value: "price-asc",
        },
      ],
      formData: {
        name: "",
        description: "",
        marca: "",
        modelo: "",
        ubicacion: "",
        precio: "",
        anio: "",
        kilometraje: "",
        tipo_de_vehiculo: "",
        carroceria: "",
        transmision: "",
        tipo_de_motor: "",
        motor: "",
        placas: "",
        version: "",
        potencia: "",
        kmh100: "",
        velocidad_max: "",
        numero_velocidades: "",
        frontal: "",
        direccion: "",
        puertas: "",
        blindaje: false,
        hibrido: false,
        garantia: false,
        lateral_1: "",
        lateral_2: "",
        posterior: "",
        interior: "",
        motor_foto: "",
        concesionario: "",
        estado: "",
      },
      imageUrlForntal: "",
      imageUrlLateral1: "",
      imageUrlLateral2: "",
      imageUrlPosterior: "",
      imageUrlMotor: "",
      imageUrlInterior: "",
      optionsEstados: [
        {
          label: "Nuevo",
          value: "nuevo",
        },
        {
          label: "Usado",
          value: "usado",
        },
      ],
      optionsMarcas: [],
      optionsModelos: [],
      optionsTipoVehicle: [
        {
          label: "Carro",
          value: "carro",
          carroceria: [
            {
              label: "Sedán",
              value: "sedan",
            },
            {
              label: "Hatchback",
              value: "hatchback",
            },
            {
              label: "Convertible",
              value: "convertible",
            },
            {
              label: "Coupe",
              value: "coupe",
            },
          ],
        },
        {
          label: "Camioneta",
          value: "camioneta",
          carroceria: [
            {
              label: "SUV",
              value: "suv",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ],
        },
      ],
      optionsCarroceria: [],
      optionsAnios: [],
      optionsPlacas: [
        {
          label: "Par",
          value: "par",
        },
        {
          label: "Impar",
          value: "impar",
        },
      ],
      optionsTransmision: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsTipoMotor: [
        {
          label: "Gasolina",
          value: "gasolina",
        },
        {
          label: "Diesel",
          value: "diesel",
        },
        {
          label: "Híbrido",
          value: "hibrido",
        },
        {
          label: "Eléctrico",
          value: "electrico",
        },
      ],
      optionsConcesionario: [],
      optionsUbicacion: [],
      vehicles: [],
      optionsPrice: [
        {
          value: null,
          label: 'Seleccionar Precio',
          disabled: true
        },
        {
          label: "$0-$40.000.000",
          value: "0-40000000"
        },
        {
          label: "$40.000.000-$60.000.000",
          value: "40000000-60000000",
        },
        {
          label: "$60.000.000-$80.000.000",
          value: "60000000-80000000",
        },
        {
          label: "$80.000.000-$100.000.000",
          value: "80000000-100000000",
        },
        {
          label: "$100.000.000-$150.000.000",
          value: "100000000-150000000",
        },
        {
          label: "$150.000.000-$200.000.000",
          value: "150000000-200000000",
        },
        {
          label: "Más de $200.000.000",
          value: "200000000",
        },
      ],
      optionsKm: [
        {
          label: "Menos de 10.000km",
          value: "0-10000",
        },
        {
          label: "10.000km-30.000km",
          value: "10000-30000",
        },
        {
          label: "30.000km-50.000km",
          value: "30000-50000",
        },
        {
          label: "50.000km-70.000km",
          value: "50000-70000",
        },
        {
          label: "70.000km-100.000km",
          value: "70000-100000",
        },
        {
          label: "Más de 100.000km",
          value: "100000",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("search", ["getVehiclesStore", "getVehiclesLengthStore", "getformDataStore"]),
  },
  mounted() {
    this.getMarcas()
    this.getAnios()
    this.getUbicacion()
    this.getConcesionarios()
    this.gridView = true
    this.getDatavuex()
    this.searchData = JSON.parse(window.localStorage.getItem("search"))
    
    if (!this.searchData) this.searchData = []
    if (this.searchData && this.searchData.length > 0) {
      this.vehicles = []
      this.vehicles = this.searchData
      this.vehiclesCount = this.searchData.length
    }
  },
  methods: {
    ...mapActions("search", ["changeFormData", "changeVehicles"]),
    getDatavuex() {
      this.vehicles = this.getVehiclesStore;
      this.vehiclesCount = this.getVehiclesLengthStore;
      this.formData = this.getformDataStore;
      if(!this.vehicles) {
        this.getVehicles()
      } 
    },
    getConcesionarios() {
      concesionarioService.getConcesionarios().then((res) => {
        res.data.forEach((concesionarios) => {
          this.optionsConcesionario.push({
            label: concesionarios.displayName,
            value: concesionarios.displayName,
          });
        });
      });
    }, 
    orderBy(order) {
      if(order == 'year-desc') {
        const orderByAnio = { changeFormData, 'order': -1}
        vehicleService.orderByAnio(orderByAnio)
        .then(vehicles => {
          this.vehicles = vehicles.data
        })
      }
      if(order == 'year-asc') {
        const order = { ...this.formData, 'order': 1}
        vehicleService.orderByAnio(order)
        .then(vehicles => {
          this.vehicles = vehicles.data
        })
      }
      if(order == 'price-desc') {
        const order = { ...this.formData, 'order': -1}
        vehicleService.orderByPrice(order)
        .then(vehicles => {
          this.vehicles = vehicles.data
        })
      }
      if(order == 'price-asc') {
        const order = { ...this.formData, 'order': 1}
        vehicleService.orderByPrice(order)
        .then(vehicles => {
          this.vehicles = vehicles.data
        })
      }
    },
    getVehicleType() {
      this.optionsCarroceria = []
      const carroceria = this.optionsTipoVehicle.filter(
        (carroceria) => carroceria.label === this.formData.tipo_de_vehiculo
      )
      this.optionsCarroceria = carroceria[0].carroceria
    },
    showGrid(e) {
      e.preventDefault()
      this.gridView = true
      this.listView = false
      this.isActiveGrid = true
      this.isActiveList = false
    },
    showList(e) {
      e.preventDefault()
      this.gridView = false
      this.listView = true
      this.isActiveGrid = false
      this.isActiveList = true
    },
    getVehicles(limit) {
      window.localStorage.removeItem("search")
      this.formData = {}
      this.changeFormData(this.formData)
      vehicleService.getVehicles(limit).then((vehicles) => {
        this.changeVehicles(vehicles.data);
        this.vehiclesCount = vehicles.data.length
        this.vehicles = vehicles.data
      })
    },
    getMarcas() {
      marcaService.getMarcas().then((res) => {
        res.data.forEach((marca) => {
          this.optionsMarcas.push({
            key: marca.id,
            label: marca.name,
            value: marca.name,
          })
        })
      })
    },
    async getMarca() {
      const marcas = await marcaService.getMarcas()
      const marca = marcas.data.filter(
        (marca) => marca.name == this.formData.marca
      )
      const modelos = []

      marca[0].modelos.forEach((modelo) => {
        modelos.push({
          label: modelo,
          value: modelo,
        })
      })

      this.optionsModelos = modelos
    },
    getAnios() {
      aniosService.getAnios().then((res) => {
        res.data.forEach((anio) => {
          this.optionsAnios.push({
            label: anio.name,
            value: anio.name,
          })
        })
      })
    },
    getUbicacion() {
      ubicacionService.getUbicacion().then((res) => {
        res.data.forEach((ubicacion) => {
          this.optionsUbicacion.push({
            label: ubicacion.name,
            value: ubicacion.name,
          })
        })
      })
    },

    getVehiclesByFilters(filter) {
      var query = {}
      if (filter) query = JSON.parse(filter)
      if (this.formData.estado) query.estado = this.formData.estado
      if (this.formData.marca) query.marca = this.formData.marca
      if (this.formData.modelo) query.modelo = this.formData.modelo
      if (this.formData.ubicacion) query.ubicacion = this.formData.ubicacion
      if (this.formData.price) query.price = this.formData.price
      if (this.formData.high_price) query.high_price = this.formData.high_price
      if (this.formData.low_anio) query.low_anio = this.formData.low_anio
      if (this.formData.high_anio) query.high_anio = this.formData.high_anio
      if (this.formData.kilometraje) query.kilometraje = this.formData.kilometraje
      if (this.formData.low_km) query.low_km = this.formData.low_km
      if (this.formData.high_km) query.high_km = this.formData.high_km
      if (this.formData.tipo_de_carro)
        query.tipo_de_carro = this.formData.tipo_de_carro
      if (this.formData.carroceria) query.carroceria = this.formData.carroceria
      if (this.formData.transmision)
        query.transmision = this.formData.transmision
      if (this.formData.tipo_de_motor)
        query.tipo_de_motor = this.formData.tipo_de_motor
      if (this.formData.placas) query.placas = this.formData.placas
      if (this.formData.concesionario)
        query.concesionario = this.formData.concesionario
      // const dataFilter = `marca=${this.formData.marca}&modelo=${this.formData.modelo}&ubicacion=${this.formData.ubicacion}&precio=${this.formData.precio}&anio=${this.formData.anio}&kilometraje=${this.formData.kilometraje}&tipo_de_vehiculo=${this.formData.tipo_de_vehiculo}&carroceria=${this.formData.carroceria}&transmision=${this.formData.transmision}&tipo_de_motor=${this.formData.tipo_de_motor}&placa=${this.formData.placas}&concesionario=${this.formData.concesionario}`
      vehicleService.getVehiclesByFilters(query).then(async (vehicles) => {
        this.changeVehicles(vehicles.data);
        this.changeFormData(query);
        this.vehicles = []
        this.vehiclesCount = vehicles.data.length
        this.vehicles = vehicles.data
      })
    },
  },
}
</script>

<style></style>
