<template>
    <div class="anuncioActions text-left mt-3">        
 
        <div class=" d-inline mr-4">
          <a href="/financiar">
            <b-button id="show-btn" class="btn-primary btnPrimaryExotics">FINANCIAMIENTO</b-button>
          </a>
      
        </div>
        <div class="modalContactarExotics d-inline">
            <a id="show-btn" class="btn-primary btnPrimaryExotics " :href="`https://wa.me/+57${info.telefono? info.telefono: '3053159933'}?text=Estoy%20interesado%20en%20este%20veh%C3%ADculo%20${info.name} \nhttps://exoticsco.com/detalle/${info._id}`" target="_blank">
              CONTACTAR<img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy">
              </a>
          <b-modal ref="my-modal" hide-footer title="Completa tus datos">
            <div class="d-block text-center">
              <div class="formComprar">
                <div class="row">
                  <div class="col-12">
                    <p class="subTitle text-left fontDarktExotics">INFORMACIÓN PERSONAL</p>
                    <input v-model="first_name" type="text" class="form-control busquedaAvanzadaInput outlineInputBlack" placeholder="NOMBRE">
                  </div>
                  <div class="col-12 mt-3">
                    <input v-model="last_name" type="text" class="form-control busquedaAvanzadaInput outlineInputBlack" placeholder="APELLIDO">
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <p class="subTitle text-left fontDarktExotics">INFORMACIÓN DE CONTACTO</p>
                    <input v-model="email" type="email" class="form-control busquedaAvanzadaInput outlineInputBlack" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="EMAIL">
                  </div>
                  <div class="col-12 mt-3">
                    <input v-model="phone" type="text" class="form-control busquedaAvanzadaInput outlineInputBlack" placeholder="CELULAR">
                  </div>
                </div>
              </div>
            </div>
            <b-button v-if=" user && user.data.phoneNumber == undefined" class="btn-primary btnPrimaryExotics mt-5" variant="outline-danger" target="_blank" :href="`https://wa.me/+573053159933?text=Me%20interesa%20el%20auto https://exotics.vercel.app${url.fullPath}%20que%20estás%20vendiendo, Mi nombre es ${first_name} ${last_name}, mi correo electrónico es ${email}`">CONTACTAR</b-button>
            <b-button v-else class="btn-primary btnPrimaryExotics mt-5" variant="outline-danger" target="_blank" :href="`https://wa.me/+57${user.phoneNumber}?text=Me%20interesa%20el%20auto https://exotics.vercel.app${url.fullPath}%20que%20estás%20vendiendo, Mi nombre es ${first_name} ${last_name}, mi correo electrónico es ${email}`">CONTACTAR</b-button>
          </b-modal>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AnuncioActions',
  props: [
    'info'
  ],
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      url: "",
      user: ""
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('SET_USER'))
    this.url = this.$route
  },
  methods: {
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      contact() {
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.btnPrimaryExotics{
  display: inline-block;
  }
</style>
