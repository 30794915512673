<template>
    <div class="asesoramosExotics">
        <Menu></Menu>
        <!-- desktop version -->
        <div class="asesoramosContainer py-5 d-none d-lg-block">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="h2Exotics text-left mb-4">
                            TE ASESORAMOS
                        </h2>
                        <p class="text-left fontLightExotics pExotics">
                        En Exotics Co tenemos una meta:<br />
                        <strong class="text-uppercase h3Exotics fontLightExotics"
                            >Verte conducir tu Sueño.</strong
                        >
                        <br /><br />
                        Te ayudamos a encontrar el vehículo que mejor se adapte a tus
                        necesidades y te ofrecemos beneficios exclusivos como una tarjeta
                        de membresía con descuentos en talleres y servicios aliados,
                        financiación y más. 
                        <br /><br />              
                        Contamos con un amplio inventario, red de
                        alianzas con los principales concesionarios del país y
                        herramientas de búsqueda para logar encontrar tu próximo vehículo.
                        <br /><br />              
                        Además, somos una plataforma especializada en 
                        la oferta de vehículos alta gama. 
                        Te asesoramos para la venta de tu vehículo al publicarlo 
                        con nosotros, contactanos para mas información
                        </p>
                    </div>
                </div>
                <div class="financiarForm pt-5">
                    <div class="infoPersonalForm">
                        <p class="subTitle text-left fontLightExotics">
                            INFORMACIÓN PERSONAL
                        </p>
                        <div class="row">
                            <div class="col-6">
                                <input v-model="firstName" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="NOMBRE">
                            </div>
                            <div class="col-6">
                                <input v-model="lastName" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="APELLIDO">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <input v-model="email" type="email" class="form-control busquedaAvanzadaInput outlineInput" aria-describedby="emailHelp" placeholder="CORREO ELECTRÓNICO">
                            </div>
                            <div class="col-6">
                                <input v-model="phone" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="CELULAR">
                            </div>
                        </div>
                    </div>
                    <div class="infoVehiculoForm">
                        <span></span>
                    </div>
                    <div class="infoVehiculoForm pt-5">
                        <!-- <p class="subTitle text-left fontLightExotics">INFORMACIÓN DEL VEHÍCULO (opcional)</p>
                        <div class="row">
                            <div class="col-4">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MARCA</button>
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MODELO</button>
                            </div>
                            <div class="col-4">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="VALOR DEL VEHÍCULO">
                            </div>
                        </div> -->
                        <div class="row text-right flex-row-reverse">
                            <div class="col-2">
                                <b-button class="btn btn-primary btnPrimaryExotics fullWidthExotics" @click=(sendEmail())>CONTÁCTANOS</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        
        <!-- mobile version -->
        <div class="financiarContainer py-5 d-lg-none">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="h2ExoticsMobile text-left">
                            TE ASESORAMOS
                        </h2>
                        <p class="text-left fontLightExotics pExotics">Te ayudamos a encontrar el carro de tus sueños</p>
                    </div>
                </div>
                <div class="financiarForm pt-5">
                    <div class="infoPersonalForm">
                        <p class="subTitle text-left fontLightExotics">INFORMACIÓN PERSONAL</p>
                        <div class="row">
                            <div class="col-6">
                                <input v-model="firstName" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="NOMBRE">
                            </div>
                            <div class="col-6">
                                <input v-model="lastName" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="APELLIDO">
                            </div>
                            <div class="col-12 mt-3">
                                <input v-model="email" type="email" class="form-control busquedaAvanzadaInput outlineInput" aria-describedby="emailHelp" placeholder="CORREO ELECTRÓNICO">
                            </div>
                            <div class="col-12 mt-3">
                                <input v-model="phone" type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="CELULAR">
                            </div>
                        </div>
                    </div>

                    <div class="infoVehiculoForm pt-5">
                        <!-- <p class="subTitle text-left fontLightExotics">INFORMACIÓN DEL VEHÍCULO (OPCIONAL)</p>
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MARCA</button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MODELO</button>
                            </div>
                            <div class="col-12 mt-3">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="VALOR DEL VEHÍCULO">
                            </div>
                        </div> -->
                        <div class="row text-right flex-row-reverse">
                            <div class="col-12">
                                <b-button class="btn btn-primary btnPrimaryExotics fullWidthExotics" @click=(sendEmail())>CONTÁCTANOS</b-button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recomendationsLogin py-5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 text-center">
            <img
              src="../assets/login-recomendations1-exotics.jpg"
              width="100%"
              class="d-inline-block align-top"
              alt="Perfil Exotics"
              loading="lazy"
            />
            <h4 class="h3Exotics fontLightExotics pt-4">(+57) 305 315 9933</h4>
            <p class="fontLightExotics">> Conversemos.</p>
          </div>
          <div class="col-12 col-md-4 text-center">
            <img
              src="../assets/login-recomendations2-exotics.jpg"
              width="100%"
              class="d-inline-block align-top"
              alt="Perfil Exotics"
              loading="lazy"
            />
            <h4 class="h3Exotics fontLightExotics pt-4">Medellín - Antioquia</h4>
            <p class="fontLightExotics">> Visítanos.</p>
          </div>
          <div class="col-12 col-md-4 text-center">
            <img
              src="../assets/login-recomendations5-exotics.jpg"
              width="100%"
              class="d-inline-block align-top"
              alt="Perfil Exotics"
              loading="lazy"
            />
            <h4 class="h3Exotics fontLightExotics pt-4">
              autos@exoticscolombia.com
            </h4>
            <p class="fontLightExotics">> Escríbenos</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import Recomendation from '@/components/Recomendation.vue'
import LoginForm from '@/components/LoginForm.vue'
import Register from '@/components/Register.vue'
import EmailService from '@/services/Email'

export default {
  name: "Asesoramos",
  components: {
    Menu,
    Footer,
    Recomendation,
    LoginForm,
    Register
  },
  data() {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      }
  },
  methods: {
      async sendEmail() {
          const data = {
              subject: "Tienes un mensaje para asesoría",
              templateID: 2672205,
              variables: {
                  first_name: this.firstName,
                  last_name: this.lastName,
                  email: this.email,
                  phone: this.phone
              }
          }
          
        
        await EmailService.sendEmail(data).then(()=>{
            this.$alert("Gracias por contáctarnos, uno de nuestros asesores te responderá en breve.", "Mensaje enviado", {
              confirmButtonText: "Aceptar",
            })

            this.firstName="";
            this.lastName="";
            this.email="";
            this.phone="";
        })



      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
