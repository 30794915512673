<template>
  <div class="loginExotics">
    <Menu></Menu>
    <div class="loginFormContainer py-5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5">
            <h2 class="h2Exotics text-left">
              LOGIN <br />
              CONCESIONARIOS
            </h2>
            <form @submit.prevent="login()" class="loginFormExotics" v-if="showL">
              <p class="fontLightExotics text-left">
                Sección exclusiva para concesionarios aliados de Exotics Co
              </p>
              <input
                type="text"
                class="form-control busquedaAvanzadaInput outlineInput"
                placeholder="USUARIO"
                v-model="form.email"
              />
              <input
                type="password"
                class="form-control busquedaAvanzadaInput outlineInput mt-3"
                placeholder="CONTRASEÑA"
                v-model="form.password"
              />
              <div
                class="d-flex justify-content-between align-items-center py-3"
              >
                <div class="">
                  <a @click="showRegister"
                    ><P class="textBtnBorder fontLightExotics text-left mb-0"
                      >REGISTRARME</P
                    ></a
                  >
                </div>
                <div class="">
                  <a @click="showRecovery"
                    ><P class="textBtnBorder fontLightExotics text-left mb-0"
                      >OLVIDÉ MI CONTRASEÑA</P
                    ></a
                  >
                </div>
                <div>
                  <button
                    class="btn btn-primary btnPrimaryExotics"
                    type="submit"
                    @click="loginForm"
                  >
                    ENTRAR >
                  </button>
                </div>
              </div>
            </form>
            <div class="loginFormExotics" v-if="showP">
              <p class="fontLightExotics text-left">
                Sección exclusiva para concesionarios aliados de Exotics Co
              </p>
              <input
                type="text"
                class="form-control busquedaAvanzadaInput outlineInput"
                placeholder="USUARIO"
              />
              <div
                class="d-flex justify-content-between align-items-center py-3"
              >
                <div class="">
                  <a @click="showRegister"
                    ><P class="textBtnBorder fontLightExotics text-left mb-0"
                      >REGISTRARME</P
                    ></a
                  >
                </div>
                <div class="">
                  <a @click="showLogin"
                    ><P class="textBtnBorder fontLightExotics text-left mb-0"
                      >INICIAR SESIÓN</P
                    ></a
                  >
                </div>
                <div>
                  <button
                    class="btn btn-primary btnPrimaryExotics"
                    type="submit"
                  >
                    RECUPERAR >
                  </button>
                </div>
              </div>
            </div>
            <form @submit.prevent.stop="register()" class="registerFormExotics" v-if="showR">
              <p class="fontLightExotics text-left">
                Sección exclusiva para concesionarios aliados de Exotics Co
              </p>
              <div class="concesioInfo">
                <p class="subTitle text-left fontLightExotics">
                  INFORMACIÓN DEL CONCESIONARIO
                </p>
                <div class="row">
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="formRegister.displayName"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="NOMBRE CONCESIONARIO"
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <input
                      v-model="formRegister.nit"
                      type="text"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="NIT"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      v-model="formRegister.city"
                      type="text"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="CIUDAD"
                    />
                  </div>
                </div>
              </div>
              <div class="infoContactConcesio mt-4">
                <p class="subTitle text-left fontLightExotics">
                  INFORMACIÓN DE CONTACTO
                </p>
                <div class="row">
                  <div class="col-6">
                    <input
                      v-model="formRegister.email"
                      type="email"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="EMAIL"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      v-model="formRegister.phoneNumber"
                      type="text"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="CELULAR"
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <input
                      v-model="formRegister.address"
                      type="text"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="DIRECCIÓN"
                    />
                  </div>
                </div>
              </div>
              <div class="contraseñaConcesio mt-4">
                <p class="subTitle text-left fontLightExotics">CONTRASEÑA</p>
                <div class="row">
                  <div class="col-6">
                    <input
                      v-model="formRegister.password"
                      type="password"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="CONTRASEÑA"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      v-model="formRegister.password2"
                      type="password"
                      class="form-control busquedaAvanzadaInput outlineInput"
                      placeholder="CONFIRMAR CONTRASEÑA"
                    />
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center py-3"
              >
                <div class="">
                  <a @click="showLogin"
                    ><P class="textBtnBorder fontLightExotics text-left mb-0"
                      >YA ESTOY REGISTRADO</P
                    ></a
                  >
                </div>
                <div>
                  <button
                    class="btn btn-primary btnPrimaryExotics"
                    type="submit"
                    @click="registerForm"
                  >
                    REGISTRARME >
                  </button>
                </div>
              </div>
            </form>
            <div class="pt-3 d-lg-block">
              <h2 class="h2ExoticsMobile h2BusquedaForm mb-5 pb-5 text-center d-sm-none">
                #CONDUCETUSUEÑO
              </h2>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="recomendationsLogin py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12">
            <img src="../assets/login-recomendations2-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
          </div>
          <div class="col-lg-8 col-12 text-lg-left">
            <h4 class="h3Exotics fontLightExotics pt-4 text-uppercase">¿Aún no tienes tu perfil creado?</h4>
            <p class="fontLightExotics">Contactanos para ayudarte.</p>
            <a href="https://wa.me/+573053159933?text=Quiero%20crear%20mi%20perfil%20en%20exotics." target="_blank">
                <b-button class="btn-primary btnPrimaryExotics mt-4">CONTÁCTANOS <img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy"></b-button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <Recomendation class="d-none d-lg-block"></Recomendation> -->
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Menu from "@/components/Menu.vue"
import Footer from "@/components/Footer.vue"
import Recomendation from "@/components/Recomendation.vue"
import LoginForm from "@/components/LoginForm.vue"
import Register from "@/components/Register.vue"
import userService from "../services/users"
import concesionarioService from "../services/Concesionarios"

export default {
  name: "Login",
  components: {
    Menu,
    Footer,
    Recomendation,
    LoginForm,
    Register,
  },
  data() {
    return {
      showL: true,
      showR: false,
      showP: false,
      showAlert: false,
      notify: '',
      alertMessage: '',
      form: {
        email: '',
        password: ''
      },
      formRegister: {
        email: '',
        password: '',
        password2: '',
        displayName: '',
        phoneNumber: '',
        address: '',
        nit: '',
        city: ''
      }
    }
  },
  methods: {
    ...mapActions("login", [ "login"]),
    showLogin(){
        this.showL = true
        this.showR = false
        this.showP = false
    },
    showRegister(){
        this.showL = false
        this.showR = true
        this.showP = false
    },
    showRecovery(){
        this.showL = false
        this.showR = false
        this.showP = true
    },
    async loginForm() {
      try{
        const result = await this.login(this.form);
        const { redirect = false } = this.$route.query
        const path = redirect ? decodeURI(redirect) : '/profile'
        this.$router.push({ path })
      }catch(e){
        console.log("Error:")
        console.log(e)
        this.$alert('Usuario o contraseña incorrectos', 'Error', {
          confirmButtonText: 'Intentar nuevamente'
        });
      }
    },
    async registerForm() {},
    async register() {
      try{
        console.log(this.formRegister)
        const {displayName, email, password, password2, phoneNumber, nit, city, address} = this.formRegister
        if(password === password2) {
          const dataUser = {
            displayName: String(displayName).trim(),
            email,
            password,
            phoneNumber,
            nit,
            city,
            address,
            "photoURL": "",
            "disabled": false,
            "additionalClaims": {
              "group": "Admin",
              "isAdmin": true
            }
          }
          concesionarioService.getConcesionarioExist(dataUser.displayName)
          .then(result =>{
            if(!result.data){ 
              userService.createUser(dataUser).then((resultUser)=>{
                if(!resultUser.data.message){
                  this.$alert('Concesionario creado correctamente.', {
                    confirmButtonText: "Aceptar",
                  }).then(()=>this.$router.go(0));
                }else{
                  this.$alert(resultUser.data.message, {
                    confirmButtonText: "Aceptar",
                  });
                }
              })    
              
              
            }else{
              this.$alert("Ya existe un concesionario con el mismo nombre.", {
                  confirmButtonText: "Aceptar",
                });
            }
          })
          // this.$store.dispatch('register', dataUser)
          
          // const { redirect = false } = this.$route.query
          // const path = redirect ? decodeURI(redirect) : '/profile'
          // this.$router.push({ path })
        }else{
          this.$alert('Las contraseñas no coinciden', 'Aviso', {
          confirmButtonText: 'Intentar nuevamente'
        });
        }
      }catch(e){
        console.log("Error:")
        console.log(e)
        this.$alert('Usuario o contraseña incorrectos', 'Error', {
          confirmButtonText: 'Intentar nuevamente'
        });
        // this.showAlert = true
        // this.notify = 'danger'
        // this.alertMessage = "Usuario o Contraseña incorrectos"
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
