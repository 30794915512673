import axios from "axios";
import configService from "../../configs/config";

const categoriaService = {};

categoriaService.crearMarcas = (data,token) => {
  console.log("this.token", token)
  return axios
    .post(`${configService.apiUrl}/marcas`,
        data,{
        headers: {
            Authorization: `Bearer ${token}`
            }
        })
    .then(res => res.data)
    .catch(error => error);
};
categoriaService.editarMarcas = (id,data,token) => {
  return axios
    .put(`${configService.apiUrl}/marcas/${id}`,
    data,{
        headers: {
            Authorization: `Bearer ${token}`
            }
        })
    .then(res => res.data)
    .catch(error => error);
};
categoriaService.eliminarMarcas = (id,token) => {
    return axios
      .delete(`${configService.apiUrl}/marcas/${id}`,
      {
          headers: {
              Authorization: `Bearer ${token}`
              }
          })
      .then(res => res.data)
      .catch(error => error);
};
categoriaService.crearAnio = (data,token) => {
    console.log("this.token", token)
    return axios
      .post(`${configService.apiUrl}/years`,
          data,{
          headers: {
              Authorization: `Bearer ${token}`
              }
          })
      .then(res => res.data)
      .catch(error => error);
};
  categoriaService.editarAnio = (id,data,token) => {
    return axios
      .put(`${configService.apiUrl}/anios/${id}`,
      data,{
          headers: {
              Authorization: `Bearer ${token}`
              }
          })
      .then(res => res.data)
      .catch(error => error);
};

export default categoriaService;
