<template>
  <div class="detalle">
    <Menu></Menu>
    <!-- desktop version detalle -->
    <div class="d-none d-lg-block">
      <div class="detalleModelo">
        <div class="container">
          <div class="goBackExotics">
            <div class="row">
              <div class="col-12">
                <a href="/vehicles">
                  <p class="textBtn mt-5 mb-4 text-left">
                    <a href="/vehicles"
                      ><i
                        class="material-icons align-middle iconDefault fontBlueExotics"
                        >keyboard_arrow_left</i
                      ></a
                    >volver al catálogo
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-left pr-5">
              <div class="imgGallery">
                <carousel
                  v-if="images && images.length > 0"
                  :starting-image="2"
                  :images="images"
                  :auto-slide-interval="10000"
                  :show-progress-bar="true"
                ></carousel>
                <carousel
                  v-else
                  :starting-image="1"
                  :images="imagesDefault"
                  :auto-slide-interval="10000"
                  :show-progress-bar="true"
                ></carousel>
              </div>
            </div>
            <div class="col-6">
              <div class="anuncioInfo fontLightExotics text-left">
                <h1>{{ vehicle.name }}</h1>
                <p class="subTitle fontBlueExotics">
                  {{ vehicle.precio | currency }} COP
                </p>
                <div class="container concesionarioLink">
                  <div class="row py-3">
                    <div class="col-2 pl-0">
                      <div class="imgProfile">
                        <img
                          src="../assets/exoticsIcon.jpg"
                          width="100%"
                          class="d-inline-block align-top"
                          alt="Perfil Exotics"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div class="col-9 text-left fontLightExotics py-3 pl-0">
                      <p class="subTitle mb-0">CONCESIONARIO</p>
                      <a :href="`/catalogo/${vehicle.concesionario}`">
                        <h3 class="h3ExoticsMobile fontLightExotics mb-0">
                          {{ vehicle.concesionario.toUpperCase() || "Exotics" }}
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="featuresPrincipales bodyText mt-4 mb-5">
                  <p class="itemPrincipal mb-0">
                    Año: <span>{{ vehicle.anio }}</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Kilometraje: <span>{{ vehicle.kilometraje }} km</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Ubicación: <span>{{ vehicle.ubicacion }}</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Estado: <span>{{ vehicle.estado }}</span>
                  </p>
                </div>
              </div>
              <!-- <div class="description mb-5">
                <p class="tabTitleDescription text-left">Descripción</p>
                <div class="fontLightExotics text-left">
                  <p class="description bodyText">
                    {{ vehicle.description }}
                  </p>
                </div>
              </div> -->
              <AnuncioActions :info="vehicle" ></AnuncioActions>
              <FichaTabs :info="vehicle"></FichaTabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version detalle -->
    <div class="d-lg-none">
      <div class="detalleModelo">
        <div class="container">
          <div class="goBackExotics">
            <div class="row">
              <div class="col-12">
                <a href="/vehicles">
                  <p class="textBtn mt-4 mb-4 text-left">
                    <a href="/vehicles"
                      ><i
                        class="material-icons align-middle iconDefault fontBlueExotics"
                        >keyboard_arrow_left</i
                      ></a
                    >volver al catálogo
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-left">
              <div class="imgGalleryM">
                <carousel
                  :starting-image="2"
                  :images="images"
                  :auto-slide-interval="10000"
                  :show-progress-bar="true"
                ></carousel>
              </div>
            </div>
            <div class="col-12 pt-4">
              <div class="anuncioInfo fontLightExotics text-left">
                <h2>{{ vehicle.name }}</h2>
                <p class="subTitle fontBlueExotics">
                  {{ vehicle.precio | currency }} COP
                </p>
                <div class="container concesionarioLink mt-4">
                  <div class="row py-2">
                    <div class="col-3 pl-0">
                      <div class="imgProfile">
                        <img
                          src="../assets/exoticsIcon.jpg"
                          width="100%"
                          class="d-inline-block align-top"
                          alt="Perfil Exotics"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div class="col-9 text-left fontLightExotics py-3 pl-0">
                      <p class="subTitle mb-0">CONCESIONARIO /</p>
                      <a :href="`/catalogo/${vehicle.concesionario}`">
                        <h3 class="h3ExoticsMobile fontLightExotics mb-0">
                          {{ vehicle.concesionario.toUpperCase() || "Exotics" }}
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="featuresPrincipales bodyText mt-4 mb-5">
                  <p class="itemPrincipal mb-0">
                    Año: <span>{{ vehicle.anio }}</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Kilometraje: <span>{{ vehicle.kilometraje }} km</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Ubicación: <span>{{ vehicle.ubicacion }}</span>
                  </p>
                  <p class="itemPrincipal mb-0">
                    Estado: <span>{{ vehicle.estado }}</span>
                  </p>
                </div>
              </div>
              <!-- <div class="description mb-5">
                <p class="tabTitleDescription text-left">Descripción</p>
                <div class="fontLightExotics text-left">
                  <p class="description bodyText">
                    {{ vehicle.description }}
                  </p>
                </div>
              </div> -->
              <AnuncioActions :info="vehicle"></AnuncioActions>
              <!-- <FichaTabs :data="vehicle.acf" class="d-none d-lg-block"></FichaTabs> -->
              <!-- <FichaTabsM :data="vehicle" class="d-lg-none"></FichaTabsM> -->
              <FichaTabs :info="vehicle"></FichaTabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MensajeAlert></MensajeAlert>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import BusquedaResultados from "../components/SearchEngine/BusquedaResultados.vue";
import Footer from "@/components/Footer.vue";
import FichaTabs from "@/components/FichaTabs.vue";
import FichaTabsM from "@/components/FichaTabsM.vue";
import AnuncioActions from "@/components/AnuncioActions.vue";
import MensajeAlert from "@/components/MensajeAlert.vue";
import Carousel from "@/components/Carousel.vue";
import vehiclesService from "@/services/vehicles";

export default {
  name: "Detalle",
  components: {
    Menu,
    Footer,
    FichaTabs,
    FichaTabsM,
    AnuncioActions,
    MensajeAlert,
    Carousel,
  },
  data() {
    return {
      vehicle: "",
      id: 0,
      pill: "",
      imagesDefault: [
        {
          id: "1",
          big:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          thumb:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
        },
        {
          id: "2  ",
          big:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          thumb:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
        },
      ],
      images: [
        {
          id: "1",
          big:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          thumb:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
        },
        {
          id: "2  ",
          big:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          thumb:
            "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
        },
      ],
      //Index of the active image on the images array
      activeImage: 0,
    };
  },
  computed: {},
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("SET_USER"));
    this.id = this.$route.params.id;
    this.getVehicle(this.id);
  },
  methods: {
    getVehicle(id) {
      vehiclesService.getVehicle(id).then((res) => {
        this.vehicle = res.data;
        this.images = [
          {
            id: 1,
            big:
              res.data.frontal ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.frontal ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
          {
            id: 2,
            big:
              res.data.lateral_1 ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.lateral_1 ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
          {
            id: 3,
            big:
              res.data.lateral_2 ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.lateral_2 ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
          {
            id: 4,
            big:
              res.data.posterior ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.posterior ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
          {
            id: 5,
            big:
              res.data.interior ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.interior ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
          {
            id: 6,
            big:
              res.data.motor_foto ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
            thumb:
              res.data.motor_foto ||
              "https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg",
          },
        ];
      });
    },
    nextImage() {
      var active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage() {
      var active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },
};
</script>

<style></style>
