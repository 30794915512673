<template>
  <div class="container py-3">
    <div class="row">
      <div class="card text-left col-5 mb-3 imgExoticsContainerM rounded-sm">
        <a :href="`/detalle/${data.id}`"  v-if="data.frontal">
          <img           
            :src="data.frontal"
            class="card-img-top imgExoticsM"
            alt="..."
          />
        </a>
        <a :href="`/detalle/${data.id}`" v-else>
          <img
            src="https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg"
            class="card-img-top imgExoticsM"
            alt="..."
          />
        </a>
        <div v-if="data.estado === 'nuevo'" class="badgeNewM">
          <p class="mb-0 fontLightExotics kmExoticsM">
            <span class="d-md-block d-none">NUEVO</span> 0 Kms
          </p>
        </div>
      </div>
      <div class="col-6 pl-0 pr-0">
        <div class="">
          <a :href="`/detalle/${data.id}`">
            <h4 class="card-title cardTitleExoticsM mb-0">{{ data.name }}</h4>
          </a>
          <p class="card-text cardPriceExoticsM mb-0">
            {{ data.precio | currency }} COP
          </p>
          <p class="mb-0">
            <span class="modelExoticsM">{{ data.anio }} | </span>
            <span class="kmExoticsM">{{ data.kilometraje }} Kms | </span>
            <span class="kmExoticsM text-capitalcase">{{ data.ubicacion }}</span>
          </p>
          <p class="kmExoticsM mb-0 d-inline">
            <span
              v-if="data.blindaje"
              class="kmExoticsM mb-0 font-weight-normal"
              >Blindado&nbsp;|</span
            >
            <span v-else></span>
          </p>
          <p class="kmExoticsM mb-0 d-inline">
            <span v-if="data.hibrido" class="kmExoticsM mb-0 font-weight-normal"
              >Híbrido&nbsp;|</span
            >
            <span v-else></span>
          </p>
          <p class="kmExoticsM mb-0 d-inline">
            <span
              v-if="data.electrico"
              class="kmExoticsM mb-0 font-weight-normal"
              >Eléctrico</span
            >
            <span v-else></span>
          </p>
          <p
            class="mb-0 mt-2 kmExoticsM font-weight-normal"
            style="color: #9e9e9e"
          >
            {{ data.concesionario }}
          </p>
          <!-- <b-button class="btn btn-primary btnPrimaryExotics mt-2" :href="`/detalle/${data.id}`">ver más > </b-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardListM",
  props: ["data"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.text-capitalcase{
    text-transform: capitalize;
}
</style>
