<template>
  <div class="profile">
    <Menu></Menu>
    <div class="profileContainer d-none d-lg-block">
      <div class="container pb-5">
        <div class="row">
          <div class="col-6">
            <div class="row py-5">
              <div class="col-3">
                <div class="imgProfile">
                  <el-upload
                    v-model="userInfo.imageUrl"
                    class="foto-uploader foto-uploader_1"
                    action="https://bsw-services-dot-besweetstudio.uk.r.appspot.com/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccess"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrl"
                      :src="imageUrl"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/exoticsIcon.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Perfil Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                </div>
              </div>

              <div class="col-9 text-left fontLightExotics py-3">
                <p class="subTitle mb-0">CONCESIONARIO /</p>
                <h3 class="h3Exotics fontLightExotics mb-0">
                  {{ userInfo.displayName }} ({{ userInfo.city }})
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  placeholder="Nombre"
                  v-model="userInfo.displayName"
                />
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput mt-3"
                  placeholder="Documento"
                  v-model="userInfo.nit"
                />
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput mt-3"
                  placeholder="Dirección"
                  v-model="userInfo.address"
                />
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-lowercase
                  "
                  placeholder="Correo"
                  v-model="userInfo.email"
                />
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-lowercase
                    mt-3
                  "
                  placeholder="Teléfono"
                  v-model="userInfo.phoneNumber"
                />
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-capitalize
                    mt-3
                  "
                  placeholder="Ciudad"
                  v-model="userInfo.city"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  class="
                    btn btn-primary
                    btnPrimaryExotics
                    mt-3
                    fullWidthExotics
                  "
                  type="submit"
                  @click="updateUser()"
                >
                  ACTUALIZAR INFORMACIÓN
                </button>

                <button
                  class="btn btn-danger btnPrimaryExotics mt-3 fullWidthExotics"
                  @click="logout()"
                >
                  CERRAR SESIÓN
                </button>
                <a href="/condiciones" class="">
                  <P
                    class="
                      mt-3
                      textBtn
                      text-left text-capitalize
                      fontLightExotics
                    "
                    >Conocer términos y condiciones</P
                  >
                </a>
              </div>
            </div>
          </div>
          <div class="col-6 pt-5">
            <div class="row py-5">
              <div class="col-6">
                <a href="/subir-modelo">
                  <img
                    src="../assets/subir-modelo-exotics-thumb.png"
                    width="100%"
                    class="d-inline-block align-top"
                    alt="Subir modelo Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="col-6">
                <a href="/vehicles">
                  <img
                    src="../assets/catalogo-completo-exotics-thumb.png"
                    width="100%"
                    class="d-inline-block align-top"
                    alt="Subir modelo Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profileContainer d-lg-none">
      <div class="container pb-5">
        <div class="row">
          <div class="col-12">
            <div class="row py-5">
              <div class="col-3">
                <div class="imgProfile">
                  <img
                    src="../assets/exoticsIcon.jpg"
                    width="100%"
                    class="d-inline-block align-top"
                    alt="Perfil Exotics"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-9 text-left fontLightExotics pt-2">
                <p class="subTitle mb-0">CONCESIONARIO /</p>
                <h3 class="h3ExoticsMobile fontLightExotics mb-0">
                  {{ userInfo.displayName }} ({{ userInfo.city }})
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  placeholder="Nombre"
                  :value="userInfo.displayName"
                />
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput mt-3"
                  placeholder="Documento"
                  :value="`NIT ${userInfo.nit}`"
                />
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput mt-3"
                  placeholder="Dirección"
                  :value="`${userInfo.address}`"
                />
              </div>
              <div class="col-12">
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-lowercase
                    mt-3
                  "
                  placeholder="Correo"
                  :value="`${userInfo.email}`"
                />
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-lowercase
                    mt-3
                  "
                  placeholder="Teléfono"
                  :value="`${userInfo.phoneNumber}`"
                />
                <input
                  type="text"
                  class="
                    form-control
                    busquedaAvanzadaInput
                    outlineInput
                    text-capitalize
                    mt-3
                  "
                  placeholder="Ciudad"
                  :value="`${userInfo.city}`"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  class="
                    btn btn-primary
                    btnPrimaryExotics
                    mt-3
                    fullWidthExotics
                  "
                  type="submit"
                  @click="updateUser()"
                >
                  ACTUALIZAR INFORMACIÓN
                </button>

                <button
                  class="btn btn-danger btnPrimaryExotics mt-3 fullWidthExotics"
                  @click="logout()"
                >
                  CERRAR SESIÓN
                </button>

                <a href="condiciones" class="">
                  <P
                    class="
                      mt-3
                      textBtn
                      text-left text-capitalize
                      fontLightExotics
                    "
                    >Conocer términos y condiciones</P
                  >
                </a>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row py-5">
              <div class="col-6">
                <a href="/subir-modelo">
                  <img
                    src="../assets/subir-modelo-exotics-thumb.png"
                    width="100%"
                    class="d-inline-block align-top"
                    alt="Subir modelo Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="col-6">
                <a href="/vehicles">
                  <img
                    src="../assets/catalogo-completo-exotics-thumb.png"
                    width="100%"
                    class="d-inline-block align-top"
                    alt="Subir modelo Exotics"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "@/components/Footer.vue";
import userService from "@/services/users";

export default {
  name: "Profile",
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      imageUrl: "",
      user: "",
      userInfo: {
        displayName: "",
        email: "",
        nit: "",
        phoneNumber: "",
        address: "",
        city: "",
        imageUrl:''
      },
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("SET_USER"));
    this.getUser(this.user.data.uid);
  },
  methods: {
    handlefotoSuccess(res, file) {
      console.log(res)
      this.imageUrl = URL.createObjectURL(file.raw)
      // console.log(this.imageUrl);
      window.localStorage.setItem("imageUrl", res.data)
    },
    beforefotoUpload(file) {
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 3

      if (!isJPG) {
        this.$message.error("La imagen debe estar en formato JPG!")
      }
      if (!isLt2M) {
        this.$message.error("La imagen excede los 2MB!")
      }
      return isJPG && isLt2M
    },
    async getUser(id) {
      const userData = await userService.getUser(id);
      console.log(userData.data);
      this.userInfo = userData.data;
      this.imageUrl = userData.data.photoURL;
    },
    async updateUser() {

      let imageUrl = window.localStorage.getItem("imageUrl");
      

      const profileData = {
        displayName: String(this.userInfo.displayName).trim(),
        email: this.userInfo.email,
        phoneNumber: this.userInfo.phoneNumber,
        nit: this.userInfo.nit,
        address: this.userInfo.address,
        city: this.userInfo.city,
        photoURL: imageUrl
      };

      await userService.updateUser(this.user.data.uid, profileData).then(() => {
        this.$alert("Concesionario actualizado correctamente.", {
          confirmButtonText: "Aceptar",
        });
        window.localStorage.removeItem("imageUrl");
      });
    },
    logout() {
      window.localStorage.removeItem("SET_USER");
      window.localStorage.removeItem("AUTH_TOKEN");
      this.$router.push("/");
    },
  },
};
</script>

<style>
input.form-control::placeholder {
  text-transform: none;
}

.el-upload {
  border: none !important;
}

</style>
