<template>
  <div class="mensajeAlert py-3">
    <router-link to="/condiciones">
      <p class="m-0 bodyText fontLightExotics">
        Al preguntar aceptas los Términos y condiciones y las Políticas de
        privacidad de Exotics Co.
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MensajeAlert",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
