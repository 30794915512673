import axios from "axios";
import configService from "../../configs/config";

const emailService = {};

emailService.sendEmail = async data => {
  console.log(data)
  const dataEmail = {
    "Messages":[
      {
        "From": {
          "Email": "autos@exoticscolombia.com",
          "Name": "ExoticsCo"
        },
        "To": [
          {
            "Email": "autos@exoticscolombia.com",
            "Name": "ExoticsCo"
          }
        ],
        "Variables": {
          ...data.variables
        },
        "TemplateLanguage": true,
        "Subject": data.subject,
        "TemplateID": data.templateID,
        "SandboxMode":true
      }
    ]
  }
  
  await axios
    .post(`${configService.apiUrl}/email`, dataEmail)
    .then(res => {
      console.log(res.data)
    })
    .catch(error => console.log(error));
};

emailService.addContact = async email => {
  const data = {
    email
  }

  await axios
    .post(`${configService.apiUrl}/add/email`, data)
    .then(res => {
      console.log(res.data)
    })
    .catch(error => console.log(error));
}

export default emailService;
