<template>
  <div class="">
    <!-- Desktop version -->
    <div class="buscadorDesktop busquedaHeader d-none d-lg-block">
      <div class="container py-5">
        <div class="row py-5">
          <div class="col-6 text-left">
            <div class="input-group mb-3">
              <input
                @keyup.enter="search()"
                type="text"
                v-model="word"
                class="form-control busquedaAvanzadaInput"
                placeholder="BUSQUEDA RÁPIDA"
                aria-label="BUSQUEDA RÁPIDA"
                aria-describedby="button-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn p-1 searchIcon"
                  type="button"
                  id="button-addon2"
                  disabled
                  @click="search()"
                >
                  <span class="material-icons">search</span>
                </button>
              </div>
            </div>
            <!-- <input
              v-model="word"
              @keyup.enter="search"
              type="text"
              class="form-control busquedaAvanzadaInput"
              placeholder="BUSQUEDA RÁPIDA"
            /> -->
            <div class="estadoModeloExotics mt-5">
              <b-form-radio-group
                v-model="formData.estado"
                :options="optionsEstados"
                class="mb-3 text-label-white"
                value-field="value"
                text-field="label"
              ></b-form-radio-group>
            </div>
            <div class="busquedaFormExotics row mt-3">
              <div class="col-md-6">
                <el-select
                  v-model="formData.marca"
                  @change="getMarca()"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Marca"
                >
                  <el-option
                    v-for="item in optionsMarcas"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <div class="row mt-3">
                  <div class="col-12">
                    <el-select
                      v-model="formData.price"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Rango de precios"
                    >
                      <el-option
                        v-for="(item, index) in optionsPrice"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <el-select
                  v-model="formData.ubicacion"
                  filterable
                  class="fullWidthExotics exoticsInput mt-3"
                  placeholder="Ubicación"
                >
                  <el-option
                    v-for="item in sortCities(optionsUbicacion)"
                    :key="item.id"
                    :aria-sort="none"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-6">
                <el-select
                  v-model="formData.modelo"
                  filterable
                  class="fullWidthExotics exoticsInput"
                  placeholder="Modelo"
                >
                  <el-option
                    v-for="item in optionsModelos"
                    :key="item.value"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <form>
                      <div class="row">
                        <div class="col exoticsInput">
                          <el-select
                            v-model="formData.low_anio"
                            filterable
                            class="fullWidthExotics exoticsInput"
                            placeholder="Año Min."
                          >
                            <el-option
                              v-for="item in optionsAnios"
                              :key="item.value"
                              :value="item.value"
                              :label="item.label"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="col exoticsInput">
                          <el-select
                            v-model="formData.high_anio"
                            filterable
                            class="fullWidthExotics exoticsInput"
                            placeholder="Año Max."
                          >
                            <el-option
                              v-for="item in optionsAnios"
                              :key="item.value"
                              :value="item.value"
                              :label="item.label"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <button
                  class="btn btn-primary btnPrimaryExotics mt-3 fullWidthExotics"
                  type="submit"
                  @click="filters()"
                >
                  buscar
                </button>
              </div>
              <a @click="cleanFilter()">
                <p class="busquedaAvanzadaBtn mt-3 ml-3">Limpiar Filtros</p>
              </a>
              <a @click="showAdvance"
                ><p class="busquedaAvanzadaBtn mt-3 ml-4">
                  BUSQUEDA AVANZADA
                </p></a
              >
            </div>
          </div>
          <div class="col-6 text-center checkboxFont">
            <h2 class="h2Exotics h2BusquedaForm pl-5">#CONDUCETUSUEÑO</h2>
            <p class="fontLightExotics pl-5">
              Encuentra tu vehículo alta gama nuevo o usado
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <div class="buscadorDesktop busquedaHeaderMobile d-lg-none">
      <div class="container py-2">
        <div class="row">
          <div class="col-12 text-left">
            <div class="input-group my-4">
              <input
                @keyup.enter="search()"
                type="text"
                v-model="word"
                class="form-control busquedaAvanzadaInput"
                placeholder="BUSQUEDA RÁPIDA"
                aria-label="BUSQUEDA RÁPIDA"
                aria-describedby="button-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn p-1 searchIcon"
                  type="button"
                  id="button-addon2"
                  disabled
                >
                  <span class="material-icons">search</span>
                </button>
              </div>
            </div>
            <div class="estadoModeloExotics mt-5">
              <b-form-radio-group
                  v-model="formData.estado"
                  :options="optionsEstados"
                  class="mb-3 text-label-white"
                  value-field="value"
                  text-field="label"
                ></b-form-radio-group>
            </div>
            <div class="busquedaFormExotics row mt-3 pb-4">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-6">
                    <el-select
                      v-model="formData.marca"
                      @change="getMarca()"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Marca"
                    >
                      <el-option
                        v-for="item in optionsMarcas"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="col-6">
                    <el-select
                      v-model="formData.modelo"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Modelo"
                    >
                      <el-option
                        v-for="item in optionsModelos"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <el-select
                      v-model="formData.low_anio"
                      filterable
                      class="fullWidthExotics exoticsInput mt-3"
                      placeholder="Año Min."
                    >
                      <el-option
                        v-for="item in optionsAnios"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="col-6">
                    <el-select
                      v-model="formData.high_anio"
                      filterable
                      class="fullWidthExotics exoticsInput mt-3"
                      placeholder="Año Max."
                    >
                      <el-option
                        v-for="item in optionsAnios"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <el-select
                      v-model="formData.price"
                      filterable
                      class="fullWidthExotics exoticsInput"
                      placeholder="Rango de precios"
                    >
                      <el-option
                        v-for="(item, index) in optionsPrice"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12">
                    <el-select
                      v-model="formData.ubicacion"
                      filterable
                      class="fullWidthExotics exoticsInput mt-3"
                      placeholder="Ubicación"
                    >
                      <el-option
                        v-for="(item, index) in sortCities(optionsUbicacion)"
                        :key="index"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <button
                  class="btn btn-primary btnPrimaryExotics mt-3 fullWidthExotics"
                  type="submit"
                  @click="filters()"
                >
                  buscar
                </button>
              </div>
              <a @click="cleanFilter()">
                <p class="busquedaAvanzadaBtn mt-4 ml-3">Limpiar Filtros</p>
              </a>
              <a @click="showAdvance"
                ><P class="busquedaAvanzadaBtn mt-4 ml-3"
                  >BUSQUEDA AVANZADA</P
                ></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="headerMobile d-lg-none">
      <div class="container">
        <div class="row pb-5">
          <div class="col-12 text-center mb-5">
            <h2 class="h2ExoticsMobile h2BusquedaForm">#CONDUCETUSUEÑO</h2>
            <p class="fontLightExotics pExoticsMobile pb-5 mb-5">
              Encuentra tu vehículo alta gama nuevo o usado
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import mixins from "../../mixins/mixins";
import vehicleService from "../../services/vehicles";
import marcaService from "../../services/marcas";
export default {
  name: "BusquedaForm",
  data() {
    return {
      word: "",
      optionsMarcas: [],
      optionsAnios: [],
      optionsPrice: [
        {
          label: "$0-$40.000.000",
          value: "0-40000000",
        },
        {
          label: "$40.000.000-$60.000.000",
          value: "40000000-60000000",
        },
        {
          label: "$60.000.000-$80.000.000",
          value: "60000000-80000000",
        },
        {
          label: "$80.000.000-$100.000.000",
          value: "80000000-100000000",
        },
        {
          label: "$100.000.000-$150.000.000",
          value: "100000000-150000000",
        },
        {
          label: "$150.000.000-$200.000.000",
          value: "150000000-200000000",
        },
        {
          label: "Más de $200.000.000",
          value: "200000000",
        },
      ],
      optionsKm: [
        {
          label: "Menos de 10.000km",
          value: "0-10000",
        },
        {
          label: "10.000km-30.000km",
          value: "10000-30000",
        },
        {
          label: "30.000km-50.000km",
          value: "30000-50000",
        },
        {
          label: "50.000km-70.000km",
          value: "50000-70000",
        },
        {
          label: "70.000km-100.000km",
          value: "70000-100000",
        },
        {
          label: "Más de 100.000km",
          value: "100000",
        },
        {
          label: "$40.000.000-$60.000.000",
          value: "60000000-80000000",
        },
        {
          label: "$60.000.000-$80.000.000",
          value: "80000000-100000000",
        },
        {
          label: "$80.000.000-$100.000.000",
          value: "100000000-150000000",
        },
        {
          label: "$100.000.000-$150.000.000",
          value: "150000000-200000000",
        },
        {
          label: "$150.000.000-$200.000.000",
          value: "150000000-200000000",
        },
        {
          label: "Más de $200.000.000",
          value: "<200000000",
        },
      ],
      optionsKm: [
        {
          label: "Menos de 10.000km",
          value: "0-10000",
        },
        {
          label: "10.000km-30.000km",
          value: "10000-30000",
        },
        {
          label: "30.000km-50.000km",
          value: "30000-50000",
        },
        {
          label: "50.000km-70.000km",
          value: "50000-70000",
        },
        {
          label: "70.000km-100.000km",
          value: "70000-100000",
        },
        {
          label: "Más de 100.000km",
          value: "100000",
        },
      ],
    };
  },
  mixins: [mixins],
  computed: {
    ...mapGetters("search", ["getformDataStore"]),
  },
  mounted() {
    this.getMarcas(), this.getDatavuex();
  },
  methods: {
    ...mapActions("search", ["changeFormData", "changeVehicles"]),
    getDatavuex() {
      this.formData = this.getformDataStore;
    },
    cleanFilter() {
      window.localStorage.removeItem("filter");
      this.formData = {};
      this.changeFormData(this.formData);
      vehicleService.getVehicles().then((vehicles) => {
        this.changeVehicles(vehicles.data);
      });
    },
    search() {
      vehicleService.search(this.word).then((vehicles) => {
        this.vehiclesCount = vehicles.data.length;
        this.vehicles = vehicles.data;
        window.localStorage.setItem("search", JSON.stringify(this.vehicles));
        this.$router.push("/search");
      });
    },
    showAdvance() {
      this.$store.dispatch("changeViewFilter", !this.$store.state.searchHide);
    },
    filters() {
      var query = {};
      if (this.formData.estado) query.estado = this.formData.estado;
      if (this.formData.marca) query.marca = this.formData.marca;
      if (this.formData.modelo) query.modelo = this.formData.modelo;
      if (this.formData.ubicacion) query.ubicacion = this.formData.ubicacion;
      if (this.formData.price) query.price = this.formData.price;
      if (this.formData.high_price) query.high_price = this.formData.high_price;
      if (this.formData.low_anio) query.low_anio = this.formData.low_anio;
      if (this.formData.high_anio) query.high_anio = this.formData.high_anio;
      if (this.formData.low_km) query.low_km = this.formData.low_km;
      if (this.formData.high_km) query.high_km = this.formData.high_km;
      if (this.formData.tipo_de_carro)
        query.tipo_de_carro = this.formData.tipo_de_carro;
      if (this.formData.carroceria) query.carroceria = this.formData.carroceria;
      if (this.formData.transmision)
        query.transmision = this.formData.transmision;
      if (this.formData.tipo_de_motor)
        query.tipo_de_motor = this.formData.tipo_de_motor;
      if (this.formData.placas) query.placas = this.formData.placas;
      if (this.formData.concesionario)
        query.concesionario = this.formData.concesionario;
      // const dataFilter = `marca=${this.formData.marca}&modelo=${this.formData.modelo}&ubicacion=${this.formData.ubicacion}&precio=${this.formData.precio}&anio=${this.formData.anio}&kilometraje=${this.formData.kilometraje}&tipo_de_vehiculo=${this.formData.tipo_de_vehiculo}&carroceria=${this.formData.carroceria}&transmision=${this.formData.transmision}&tipo_de_motor=${this.formData.tipo_de_motor}&placa=${this.formData.placas}&concesionario=${this.formData.concesionario}`
      vehicleService.getVehiclesByFilters(query).then(async (vehicles) => {
        window.localStorage.setItem("search", JSON.stringify([]));
        this.changeVehicles(vehicles.data);
        this.changeFormData(query);
        this.vehicles = [];
        this.vehiclesCount = vehicles.length;
        this.vehicles = vehicles.data;
        this.$router.push("/results");
      });
    },
    getMarcas() {
      marcaService.getMarcas().then((res) => {
        res.data.forEach((marca) => {
          this.optionsMarcas.push({
            key: marca.id,
            label: marca.name,
            value: marca.name,
          });
        });
      });
    },
    async getMarca() {
      const marcas = await marcaService.getMarcas();
      const marca = marcas.data.filter(
        (marca) => marca.name == this.formData.marca
      );
      const modelos = [];

      marca[0].modelos.forEach((modelo) => {
        modelos.push({
          label: modelo,
          value: modelo,
        });
      });

      this.optionsModelos = modelos;
    },
    sortCities(cities) {
      let sortIndx = 1;
      let preSort = {
        "Bogotá D.C": ++sortIndx,
        "Medellín": ++sortIndx,
        "Cali": ++sortIndx,
        "Barranquilla": ++sortIndx,
      };
      let preSortItems = Object.keys(preSort);

      let citiesTop = cities
        .filter(item=> preSortItems.indexOf(item.label) > -1)
        .map((item) => ({ ...item, order: preSort[item.label] }))
        .sort((a, b) => a.order - b.order);
        

      let _cities = cities
        .filter((item) => preSortItems.indexOf(item.label) == -1)
        .sort((a, b) => a.label > b.label? 1 : -1 );

      return [...citiesTop, ..._cities];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
