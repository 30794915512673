<template>
    <div class="publicarExotics uploadModelContainer">
        <Menu></Menu>
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-lg-8 text-left">
                    <h2 class="h2Exotics mt-4">
                    PUBLICA <br />
                    TU VEHÍCULO
                    </h2>
                    <p class="fontLightExotics text-left mt-4">Exoticsco.com es la plataforma virtual más importante del país enfocada en el mercado de vehículos de alta gama. Ofrecemos integración entre nuestras redes sociales y sitio web, abarcando una gran cantidad de personas por diferentes medios.</p>
                </div>
            </div>
            <!-- desktop version -->
            <div class="d-none d-lg-block">
                <div class="row text-left py-5 mt-5">
                    <div class="col-6">
                        <img src="../assets/login-recomendations2-exotics.jpg" width="70%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                        <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">¿Estás vendiendo tu vehículo <br>y quieres venderlo con ExoticsCo?</p>
                        <p class="fontLightExotics">Contactanos para ofrecerte las diferentes opciones <br>de venta que tenemos para ti. </p>
                        <a href="https://wa.me/+573053159933?text=Estoy vendiendo un vehículo y me gustaría adquirir más información" target="_blank">
                            <b-button class="btn-primary btnPrimaryExotics">CONTÁCTANOS   <img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy"></b-button>
                        </a>
                    </div>
                    <div class="col-6">
                        <img src="../assets/login-recomendations5-exotics.jpg" width="70%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                        <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">¿Eres un <br>concesionario?</p>
                        <p class="fontLightExotics">Contactanos para mostrarte los diferentes beneficios<br> que tenemos para ti.</p>
                        <a href="https://wa.me/+573053159933?text=Soy un concesionario y me gustaría adquirir más información" target="_blank">
                            <b-button class="btn-primary btnPrimaryExotics">CONTÁCTANOS <img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy"></b-button>
                        </a>
                    </div>
                </div>
            </div>
            <!-- mobile version -->
            <div class="row py-5 mt-5 d-lg-none">
                <div class="col-12 mb-5">
                    <img src="../assets/login-recomendations2-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                    <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">¿Estás vendiendo tu vehículo y quieres venderlo con ExoticsCo?</p>
                    <p class="fontLightExotics">Contactanos para ofrecerte las diferentes opciones de venta que tenemos para ti. </p>
                    <a href="https://wa.me/+573053159933?text=Estoy vendiendo un vehículo y me gustaría adquirir más información" target="_blank">
                        <b-button class="btn-primary btnPrimaryExotics mb-4">CONTÁCTANOS   <img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy"></b-button>
                    </a>
                </div>
                <div class="col-12">
                    <img src="../assets/login-recomendations5-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                    <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">¿Eres un concesionario?</p>
                    <p class="fontLightExotics">Contactanos para mostrarte los diferentes beneficios que tenemos para ti.</p>
                    <a href="https://wa.me/+573053159933?text=Soy un concesionario y me gustaría adquirir más información" target="_blank">
                        <b-button class="btn-primary btnPrimaryExotics">CONTÁCTANOS <img src="../assets/WAPP-ICON-EXOTICS.png" width="17" class="ml-2 d-inline-block align-top" alt="Instagram Exotics" loading="lazy"></b-button>
                    </a>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    
</template>

<script>
import Menu from '@/components/Menu.vue'
import Card from '../components/Card.vue'
import ResultsList from '../components/ResultsList.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Publicar',
  components: {
    Menu,
    Footer,
    Card,
    ResultsList
  },
  data() {
      return{
          gridView:true,
          listView:false
      }
  },
  methods: {
     showGrid(e){
         e.preventDefault();
         this.gridView=true
         this.listView=false
     },
     showList(e){
         e.preventDefault();
         this.gridView=false
         this.listView=true
     } 
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
