<template>
    <div class="container">
        <div class="row">
            <div class="card text-left col-md-6 mb-5 imgExoticsContainer rounded-sm">
                <img v-if="data.frontal" :src="data.frontal" class="card-img-top imgExotics rounded-sm" alt="...">
                <img v-else src="https://storage.googleapis.com/digammastudio/exotics/images/exotics-placeholder.jpg" class="card-img-top imgExotics rounded-sm" alt="...">
                <div v-if="data.estado === 'nuevo'" class="badgeNew">
                    <p class="mb-0 fontLightExotics kmExoticsM"><span class="d-md-block d-none">NUEVO</span> 0 Kms</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card-body">
                    <h5 class="card-title cardTitleExotics mb-0">{{data.name}}</h5>
                    <p class="card-text cardPriceExotics">{{data.precio | currency}} COP</p>
                    <p class="mb-0">
                        <span class="modelExotics">{{data.anio}}  | </span>
                        <span class="kmExotics">{{data.kilometraje}} Kms  | </span>
                        <span class="kmExotics text-capitalcase">{{data.ubicacion}}</span>
                    </p>
                    <p class="kmExotics mb-0 d-inline">
                        <span v-if="data.blindaje" class="kmExotics mb-0 font-weight-normal">Blindado&nbsp;|</span>
                        <span v-else></span>
                    </p>
                    <p class="kmExotics mb-0 d-inline">
                        <span v-if="data.hibrido" class="kmExotics mb-0 font-weight-normal">Híbrido&nbsp;|</span>
                        <span v-else></span>
                    </p>
                    <p class="kmExotics mb-0 d-inline">
                        <span v-if="data.electrico" class="kmExotics mb-0 font-weight-normal">Eléctrico</span>
                        <span v-else></span>
                    </p>
                    <p class="mb-0 mt-2 kmExotics font-weight-normal" style="color: #9e9e9e">{{ data.concesionario}}</p>
                    <b-button class="btn btn-primary btnPrimaryExotics mt-2" :href="`/detalle/${data._id}`">ver más > </b-button>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardList',
  props: [
      'data'
  ]
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.text-capitalcase{
    text-transform: capitalize;
}

</style>
