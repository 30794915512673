<template>
    <div class="registerFormExotics">
        <p class="fontLightExotics text-left">Sección exclusiva para concesionarios aliados de Exotics Co</p>
        <div class="concesioInfo">
            <p class="subTitle text-left fontLightExotics">INFORMACIÓN DEL CONCESIONARIO</p>
            <div class="row">
                <div class="col-12">
                    <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="NOMBRE CONCESIONARIO">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="NIT">
                </div>
                <div class="col-6">
                    <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="CIUDAD">
                </div>
            </div>
        </div>
        <div class="infoContactConcesio mt-4">
            <p class="subTitle text-left fontLightExotics">INFORMACIÓN DE CONTACTO</p>
            <div class="row">
                <div class="col-6">
                    <input type="email" class="form-control busquedaAvanzadaInput outlineInput" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="EMAIL">
                </div>
                <div class="col-6">
                    <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="CELULAR">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="DIRECCIÓN">
                </div>
            </div>
        </div>
        <div class="contraseñaConcesio mt-4">
            <p class="subTitle text-left fontLightExotics">CONTRASEÑA</p>
            <div class="row">
                <div class="col-6">
                     <input type="password" class="form-control busquedaAvanzadaInput outlineInput" id="exampleInputPassword1" placeholder="CONTRASEÑA">
                </div>
                <div class="col-6">
                     <input type="password" class="form-control busquedaAvanzadaInput outlineInput" id="exampleInputPassword1" placeholder="CONFIRMAR CONTRASEÑA">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center py-3">
            <div class="">
                <a href="#"><P class="textBtnBorder fontLightExotics text-left mb-0">YA ESTOY REGISTRADO</P></a>
            </div>
            <div>
                <button class="btn btn-primary btnPrimaryExotics" type="submit">REGISTRARME ></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Register',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
