<template>
  <div class="subirModelo">
    <Menu></Menu>
    <!-- Desktop version -->
    <div class="uploadModelContainer text-left py-5">
      <div class="container">
        <h2 class="h2Exotics mt-4">
          SUBIR <br />
          MODELO
        </h2>
        <div class="uploadForm mt-4">
          <div class="row">
            <div class="col-lg-3">
              <label for="" class="form-control-label fontLightExotics"
                >Estado</label
              >
              <b-form-radio-group
              v-model="formData.estado"
              :options="optionsEstados"
              class="mb-3 fontLightExotics text-white"
              value-field="value"
              text-field="label"
            ></b-form-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Marca *</label
              >
              <el-select
                v-model="formData.marca"
                @change="getMarca()"
                filterable
                class="mb-4 w-100"
                placeholder="Marca"
              >
                <el-option
                  v-for="item in optionsMarcas"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Modelo *</label
              >
              <el-select
                v-model="formData.modelo"
                filterable
                class="mb-4 w-100"
                placeholder="Modelo"
              >
                <el-option
                  v-for="item in optionsModelos"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Tipo de vehículo *</label
              >
              <el-select
                v-model="formData.tipo_de_vehiculo"
                @change="getVehicleType()"
                filterable
                class="mb-4 w-100"
                placeholder="Tipo de vehículo"
              >
                <el-option
                  v-for="item in optionsTipoVehicle"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Carrocería</label
              >
              <el-select
                v-model="formData.carroceria"
                filterable
                class="mb-4 w-100"
                placeholder="Carrocería"
              >
                <el-option
                  v-for="item in optionsCarroceria"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Placa *</label
              >
              <el-select
                v-model="formData.placas"
                filterable
                class="mb-4 w-100"
                placeholder="Placa"
              >
                <el-option
                  v-for="item in optionsPlacas"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Precio *</label
              >
              <el-input
                placeholder="Precio"
                v-model="formData.precio"
              ></el-input>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Ubicación *</label
              >
              <el-select
                v-model="formData.ubicacion"
                filterable
                class="mb-4 w-100"
                placeholder="Ubicación"
              >
                <el-option
                  v-for="item in sortCities(optionsUbicacion)"
                  :key="item.id"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Kilometraje *</label
              >
              <el-input
                placeholder="Kilometraje"
                v-model="formData.kilometraje"
              ></el-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Año *</label
              >
              <el-select
                v-model="formData.anio"
                filterable
                class="mb-4 w-100"
                placeholder="Año"
              >
                <el-option
                  v-for="item in optionsAnios"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Transmisión *</label
              >
              <el-select
                v-model="formData.transmision"
                filterable
                class="mb-4 w-100"
                placeholder="Transmisión"
              >
                <el-option
                  v-for="item in optionsTransmision"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3">
              <label for="" class="form-control-label fontLightExotics"
                >Tipo de motor *</label
              >
              <el-select
                v-model="formData.tipo_de_motor"
                filterable
                class="mb-4 w-100"
                placeholder="Tipo de motor"
              >
                <el-option
                  v-for="item in optionsTipoMotor"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-lg-3 mb-4">
              <label for="" class="form-control-label fontLightExotics"
                >Teléfono *</label
              >
              <el-input
                placeholder="Teléfono"
                v-model="formData.telefono"
              ></el-input>
            </div>

            <div class="col-12 mb-4">
              <label for="" class="form-control-label fontLightExotics"
                >Fotos</label
              >
              <p class="text-white">* Las imágenes no pueden exceder los 3mb de peso y los tamaños recomendados para estas son de 500px por 500px</p>
              <div class="row">
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.frontal"
                    class="foto-uploader foto-uploader_1"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessFrontal"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlForntal"
                      :src="imageUrlForntal"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-frontal-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Frontal
                  </h5>
                </div>
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.lateral_1"
                    class="foto-uploader foto-uploader_2"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessLateral1"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlLateral1"
                      :src="imageUrlLateral1"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-lateral1-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Lateral 1
                  </h5>
                </div>
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.lateral_2"
                    class="foto-uploader foto-uploader_3"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessLateral2"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlLateral2"
                      :src="imageUrlLateral2"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-lateral2-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Lateral 2
                  </h5>
                </div>
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.posterior"
                    class="foto-uploader foto-uploader_4"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessPosterior"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlPosterior"
                      :src="imageUrlPosterior"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-posterior-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Posterior
                  </h5>
                </div>
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.motor_foto"
                    class="foto-uploader foto-uploader_5"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessMotor"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlMotor"
                      :src="imageUrlMotor"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-motor-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Motor
                  </h5>
                </div>
                <div class="col-lg-2 col-6">
                  <el-upload
                    v-model="formData.interior"
                    class="foto-uploader foto-uploader_6"
                    action="https://digamma-services-beubikow5q-uk.a.run.app/upload"
                    :data="{ location: 'images', project: 'exotics' }"
                    :show-file-list="false"
                    :on-success="handlefotoSuccessInterior"
                    :before-upload="beforefotoUpload"
                  >
                    <img
                      v-if="imageUrlInterior"
                      :src="imageUrlInterior"
                      class="foto"
                    />
                    <img
                      v-else
                      src="../assets/upload-interior-exotics.jpg"
                      width="100%"
                      class="d-inline-block align-top"
                      alt="Vistas Exotics"
                      loading="lazy"
                    />
                  </el-upload>
                  <h5 class="text-muted text-center font-weight-normal">
                    Interior
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="container py-4">
            <div class="row text-left w-100">
              <div class="col-12 text-left">
                <el-checkbox
                  class="fontLightExotics"
                  v-model="formData.garantia"
                  >Garantía vigente</el-checkbox
                >
                <el-checkbox
                  class="fontLightExotics"
                  v-model="formData.blindaje"
                  >Blindado</el-checkbox
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 mb-4 mt-4">
              <label for="" class="form-control-label fontLightExotics"
                >Descripción</label
              >
              <el-input
                type="textarea"
                placeholder="Excelente estado..."
                v-model="formData.description"
              ></el-input>
            </div>
            <div class="form-check fullWidthExotics col-12 mb-4 mt-5">
              <p class="subTitle fontLightExotics" for="defaultCheck1">
                AGREGAR INFORMACIÓN ADICIONAL (OPCIONAL)
              </p>
              <p class="form-text text-muted">
                En esta sección podrás agregar información sobre la versión, el
                motor, la potencia entre otros.
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4 mb-3">
              <label for="" class="form-control-label fontLightExotics"
                >Versión</label
              >
              <el-input
                placeholder="Versión"
                v-model="formData.version"
              ></el-input>
            </div>
            <div class="col-lg-2 mb-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Motor</label
              >
              <el-input placeholder="Ej: 1.4 litros" v-model="formData.motor">
              </el-input>
            </div>
            <div class="col-lg-2 mb-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Potencia</label
              >
              <el-input placeholder="Ej: 150 hp" v-model="formData.potencia">
                <template slot="append">hp</template>
              </el-input>
            </div>
            <div class="col-lg-2 mb-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >0-100 km/h</label
              >
              <el-input placeholder="Ej: 7s" v-model="formData.kmh100">
                <template slot="append">s</template>
              </el-input>
            </div>
            <div class="col-lg-2 mb-3 col-6">
              <label for="" class="form-control-label fontLightExotics"
                >Velocidad máxima</label
              >
              <el-input placeholder="Ej: 250 km/h" v-model="formData.velocidad_max">
                <template slot="append">km/h</template>
              </el-input>
            </div>
          </div>
          <div class="row text-right flex-row-reverse py-5">
            <div class="col-lg-2 col-12">
              <b-button
                class="btn btn-primary btnPrimaryExotics fullWidthExotics"
                @click="crearVehicle()"
                >SUBIR MODELO</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Destacados class="d-none d-lg-block"></Destacados>
    <Footer></Footer>
  </div>
</template>

<script>
import vehicleService from "../services/vehicles"
import marcaService from "../services/marcas"
import aniosService from "../services/Anios"
import ubicacionService from "../services/Ubicacion"
import categoriaService from "../services/Categorias"
import Menu from "../components/Menu.vue"
import Footer from "@/components/Footer.vue"
import Destacados from "../components/Destacados.vue"
import CardDestacados from "../components/CardDestacados.vue"
import CardDestacadosM from "../components/CardDestacadosM.vue"

export default {
  name: "CreateCarro",
  components: {
    Menu,
    Footer,
    Destacados,
    CardDestacados,
    CardDestacadosM,
  },
  data() {
    return {
      formData: {
        name: "",
        description: "",
        marca: "",
        modelo: "",
        ubicacion: "",
        precio: "",
        anio: "",
        kilometraje: "",
        tipo_de_vehiculo: "",
        carroceria: "",
        transmision: "",
        tipo_de_motor: "",
        motor: "",
        placas: "",
        version: "",
        potencia: "",
        kmh100: "",
        velocidad_max: "",
        numero_velocidades: "",
        frontal: "",
        direccion: "",
        puertas: "",
        blindaje: false,
        hibrido: false,
        garantia: false,
        lateral_1: "",
        lateral_2: "",
        posterior: "",
        interior: "",
        motor_foto: "",
        concesionario: "",
        estado: "",
      },
      imageUrlForntal: "",
      imageUrlLateral1: "",
      imageUrlLateral2: "",
      imageUrlPosterior: "",
      imageUrlMotor: "",
      imageUrlInterior: "",
      optionsEstados: [
        {
          label: "Nuevo",
          value: "nuevo",
        },
        {
          label: "Usado",
          value: "usado",
        },
      ],
      optionsMarcas: [],
      optionsModelos: [],
      optionsTipoVehicle: [
        {
          label: "Carro",
          value: "carro",
          carroceria: [
            {
              label: "Sedán",
              value: "sedan",
            },
            {
              label: "Hatchback",
              value: "hatchback",
            },
            {
              label: "Convertible",
              value: "convertible",
            },
            {
              label: "Coupe",
              value: "coupe",
            },
          ],
        },
        {
          label: "Camioneta",
          value: "camioneta",
          carroceria: [
            {
              label: "SUV",
              value: "suv",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ],
        },
      ],
      optionsCarroceria: [],
      optionsAnios: [],
      optionsPlacas: [
        {
          label: "Par",
          value: "par",
        },
        {
          label: "Impar",
          value: "impar",
        },
      ],
      optionsTransmision: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsTipoMotor: [
        {
          label: "Gasolina",
          value: "gasolina",
        },
        {
          label: "Diesel",
          value: "diesel",
        },
        {
          label: "Híbrido",
          value: "hibrido",
        },
        {
          label: "Eléctrico",
          value: "electrico",
        },
      ],
      optionsConcesionario: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsUbicacion: [],
    }
  },
  mounted() {
    // Everything is mounted and you can access the dropzone instance
    const user = JSON.parse(localStorage.getItem('SET_USER'))
    this.concesionario = user.data.displayName.toLowerCase()
    this.getMarcas()
    this.getAnios()
    this.getUbicacion()
  },
  filters: {
    numberFormat(num) {
      return (
        num
          .toFixed(2) // always two decimal digits
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      ) // use . as a separator
    }
  },
  methods: {
    getVehicleType() {
      this.optionsCarroceria = []
      const carroceria = this.optionsTipoVehicle.filter(
        (carroceria) => carroceria.value === this.formData.tipo_de_vehiculo
      )
      this.optionsCarroceria = carroceria[0].carroceria
    },
    handlefotoSuccessFrontal(res, file) {
      console.log(res)
      this.imageUrlForntal = URL.createObjectURL(file.raw)
      window.localStorage.setItem("frontal", res.data)
    },
    handlefotoSuccessLateral1(res, file) {
      this.imageUrlLateral1 = URL.createObjectURL(file.raw)
      window.localStorage.setItem("lateral1", res.data)
    },
    handlefotoSuccessLateral2(res, file) {
      this.imageUrlLateral2 = URL.createObjectURL(file.raw)
      window.localStorage.setItem("lateral2", res.data)
    },
    handlefotoSuccessPosterior(res, file) {
      this.imageUrlPosterior = URL.createObjectURL(file.raw)
      window.localStorage.setItem("posterior", res.data)
    },
    handlefotoSuccessMotor(res, file) {
      this.imageUrlMotor = URL.createObjectURL(file.raw)
      window.localStorage.setItem("motor", res.data)
    },
    handlefotoSuccessInterior(res, file) {
      this.imageUrlInterior = URL.createObjectURL(file.raw)
      window.localStorage.setItem("interior", res.data)
    },
    beforefotoUpload(file) {
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 3

      if (!isJPG) {
        this.$message.error("La imagen debe estar en formato JPG!")
      }
      if (!isLt2M) {
        this.$message.error("La imagen excede los 2MB!")
      }
      return isJPG && isLt2M
    },
    crearVehicle() {
      if (
        this.formData.estado &&
        this.formData.marca &&
        this.formData.modelo &&
        this.formData.ubicacion &&
        this.formData.precio &&
        this.formData.anio &&
        this.formData.kilometraje &&
        this.formData.tipo_de_vehiculo &&
        this.formData.transmision &&
        this.formData.tipo_de_motor &&
        this.formData.placas
      ) {
        const frontal = window.localStorage.getItem("frontal")
        const lateral1 = window.localStorage.getItem("lateral1")
        const lateral2 = window.localStorage.getItem("lateral2")
        const posterior = window.localStorage.getItem("posterior")
        const interior = window.localStorage.getItem("interior")
        const motor = window.localStorage.getItem("motor")

        const vehicleData = {
          name: `${this.formData.marca} ${this.formData.modelo}`,
          description: this.formData.description,
          marca: this.formData.marca,
          modelo: this.formData.modelo,
          ubicacion: this.formData.ubicacion,
          precio: this.formData.precio,
          anio: this.formData.anio,
          kilometraje: this.formData.kilometraje,
          tipo_de_vehiculo: this.formData.tipo_de_vehiculo,
          carroceria: this.formData.carroceria,
          transmision: this.formData.transmision,
          tipo_de_motor: this.formData.tipo_de_motor,
          telefono: this.formData.telefono,
          motor: this.formData.motor,
          placas: this.formData.placas,
          version: this.formData.version,
          potencia: this.formData.potencia,
          kmh100: this.formData.kmh100,
          velocidad_max: this.formData.velocidad_max,
          numero_velocidades: this.formData.numero_velocidades,
          direccion: this.formData.direccion,
          puertas: this.formData.puertas,
          blindaje: this.formData.blindaje,
          hibrido: this.formData.hibrido,
          garantia: this.formData.garantia,
          frontal: frontal || "",
          lateral_1: lateral1 || "",
          lateral_2: lateral2 || "",
          posterior: posterior || "",
          interior: interior || "",
          motor_foto: motor || "",
          concesionario: this.concesionario.toLowerCase(),
          estado: this.formData.estado,
          approved: false
        }
        console.log("vehicleData", vehicleData)
        vehicleService
          .crearVehicle(vehicleData, this.token)
          .then((res) => {
            console.log("res", res)
            this.$alert("Vehiculo creado correctamente", "Genial", {
              confirmButtonText: "Aceptar",
            })
            this.$router.push("/profile");
          })
          .catch((error) => {
            console.log("error", error)
            this.$alert(
              "Por favor llena los campos requeridos",
              "Campos requeridos",
              {
                confirmButtonText: "Intentar de nuevo",
              }
            )
          })
      } else {
        this.$alert(
          "Por favor llena los campos requeridos",
          "Campos requeridos",
          {
            confirmButtonText: "Intentar de nuevo",
          }
        )
      }
    },
    getMarcas() {
      marcaService.getMarcas().then((res) => {
        res.data.forEach((marca) => {
          this.optionsMarcas.push({
            key: marca.id,
            label: marca.name,
            value: marca.name,
          })
        })
      })
    },
    async getMarca() {
      const marcas = await marcaService.getMarcas()
      const marca = marcas.data.filter(
        (marca) => marca.name == this.formData.marca
      )
      const modelos = []

      marca[0].modelos.forEach((modelo) => {
        modelos.push({
          label: modelo,
          value: modelo,
        })
      })

      this.optionsModelos = modelos
    },
    getAnios() {
      aniosService.getAnios().then((res) => {
        res.data.forEach((anio) => {
          this.optionsAnios.push({
            label: anio.name,
            value: anio.name,
          })
        })
      })
    },
    getUbicacion() {
      ubicacionService.getUbicacion().then((res) => {
        res.data.forEach((ubicacion) => {
          this.optionsUbicacion.push({
            label: ubicacion.name,
            value: ubicacion.name,
          })
        })
      })
    },
    sortCities(cities) {
      let sortIndx = 1;
      let preSort = {
        "Bogotá D.C": ++sortIndx,
        "Medellín": ++sortIndx,
        "Cali": ++sortIndx,
        "Barranquilla": ++sortIndx,
      };
      let preSortItems = Object.keys(preSort);

      let citiesTop = cities
        .filter(item=> preSortItems.indexOf(item.label) > -1)
        .map((item) => ({ ...item, order: preSort[item.label] }))
        .sort((a, b) => a.order - b.order);
        

      let _cities = cities
        .filter((item) => preSortItems.indexOf(item.label) == -1)
        .sort((a, b) => a.label > b.label? 1 : -1 );

      return [...citiesTop, ..._cities];
    },
  },
}
</script>

<style>
.foto-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.foto-uploader .el-upload:hover {
  border-color: #409eff;
}
.foto-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  max-height: 90px;
  line-height: 90px;
  text-align: center;
}
.foto {
  width: 100%;
  max-height: 90px;
  display: block;
}

.foto-uploader_1 {
  background-size: cover;
  min-height: 100px;
}
</style>
