'use strict'

export default {
  namespaced: true,
  state: {
    search: "",
    vehicles: [],
    vehiclesCount: "",
    formData: {
      name: "",
      description: "",
      marca: "",
      modelo: "",
      ubicacion: "",
      price: "",
      anio: "",
      kilometraje: "",
      tipo_de_vehiculo: "",
      carroceria: "",
      transmision: "",
      tipo_de_motor: "",
      motor: "",
      placas: "",
      version: "",
      potencia: "",
      kmh100: "",
      velocidad_max: "",
      numero_velocidades: "",
      frontal: "",
      direccion: "",
      puertas: "",
      blindaje: false,
      hibrido: false,
      garantia: false,
      lateral_1: "",
      lateral_2: "",
      posterior: "",
      interior: "",
      motor_foto: "",
      concesionario: "",
      estado: "",
    },
  },
  actions: {
    async search({commit}, filters) {
      commit('SET_USER', filters)
    },
    async changeVehicles({commit}, vehicles) {
      commit('SET_VEHICLES', vehicles)
      commit('SET_VEHICLES_LENGTH', vehicles.length)
    },
    async changeFormData({commit}, formData) {
      commit('SET_FORM_DATA', formData)
    },
  },
  mutations: {
    SET_SEARCH(state, payload) {
      state.search = payload
    },
    SET_VEHICLES(state, payload) {
      state.vehicles = payload
    },
    SET_VEHICLES_LENGTH(state, payload) {
      state.vehiclesCount = payload
    },
    SET_FORM_DATA(state, payload) {
      state.formData = payload
    }
  },
  getters: {
    search: state => state.search,
    getVehiclesStore: state => state.vehicles,
    getVehiclesLengthStore: state => state.vehiclesCount,
    getformDataStore: state => state.formData
  }
}