import axios from "axios";
import configService from "../../configs/config";

const concesionarioService = {};

concesionarioService.getConcesionarios = (token) => {
  return axios
    .get(`${configService.apiUrl}/concesionarios`)
    .then(res => res.data)
    .catch(error => error);
};

concesionarioService.getConcesionario = (id) => {
  return axios
    .get(`${configService.apiUrl}/concesionarios/${id}`)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(error => error);
};
concesionarioService.getConcesionarioByName = (concesionario) => {
  console.log(concesionario);
  return axios
    .get(`${configService.apiUrl}/concesionario`, {
      params: {
        concesionario
      }
    })
    .then(res => {
      console.log(res)
      return res.data.data.pop()
    })
    .catch(error => error);
};

concesionarioService.getConcesionarioExist = (concesionario) => {
  console.log(concesionario);
  return axios
    .get(`${configService.apiUrl}/concesionario/exist`, {
      params: {
        concesionario
      }
    })
    .then(res => {
      console.log(res)
      return res.data;
    })
    .catch(error => error);
};
concesionarioService.crearConcesionario = (data,token) => {
  console.log("this.token", token)
  return axios
    .post(`${configService.apiUrl}/concesionarios`,
        data,{
        headers: {
            Authorization: `Bearer ${token}`
            }
        })
    .then(res => res.data)
    .catch(error => error);
};

export default concesionarioService;
