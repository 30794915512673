import Vue from 'vue'
import Vuex from 'vuex'
import loginStore from './Login'
import searchStore from './Search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchHide :false,
  },
  actions: {
    changeViewFilter({ commit }, value) {
      commit("SET_SEARCHHIDE", value)
    }
  },
  mutations: {
    SET_SEARCHHIDE(state, payload) {
      state.searchHide = payload
    }
  },
  getters: {
    getSearchHide: state => state.searchHide,
  },
  modules: {
    login: loginStore,
    search: searchStore
  }
})
