<template>
    <div class="resultsHeader">
        <!-- desktop version -->
        <div class="container py-5 d-none d-lg-block">
            <div class="row py-3">
                <div class="col-12 col-md-4 text-left">
                    <h2 class="h2Exotics d-none d-lg-block">
                        ESCOGE TUS PREFERENCIAS
                    </h2>
                    <button @click="resetFilters()" class="btn btn-primary btnPrimaryExotics mt-2" >nueva búsqueda</button>
                </div>
                <!-- <div class="col-12 col-md-4 text-left checkboxFont">
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            MERCEDES
                        </label>
                    </div>
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            2018
                        </label>
                    </div>
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            MECÁNICO
                        </label>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- mobile version -->
        <div class="container d-lg-none">
            <div class="row py-3">
                <div class="col-12 col-md-4 text-left">
                    <h2 class="h2ExoticsMobile">
                        ESCOGE<br> TUS PREFERENCIAS
                    </h2>
                    <button @click="resetFilters()" class="btn btn-primary btnPrimaryExotics mt-2" >nueva búsqueda</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'BusquedaResultados',
  methods: {
      ...mapActions("search", ["changeFormData", "changeVehicles"]),
      resetFilters(){
        this.formData = {}
        this.changeFormData(this.formData)
        this.$router.push('/')
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
