import axios from "axios";
import configService from "../../configs/config";

const ubicacionService = {};

ubicacionService.getUbicacion = () => {
  return axios
    .get(`${configService.apiUrl}/locations`)
    .then(res => res.data)
    .catch(error => error);
};



export default ubicacionService;
