<template>
  <div class="catalogoExotics">
    <Menu></Menu>
    <div class="catalogoConcesionario">
      <div class="container">
        <div class="row py-5">
          <div class="col-lg-2 col-5 d-none">
            <div class="imgProfile">
              <img
                :src="concesionario.photoURL"
                width="100%"
                class="d-inline-block align-top text-lg-left text-center"
                alt="Perfil Exotics"
                loading="lazy"
              />
              <!-- <img  src="../assets/mercedes-benz.jpg" width="100%" class="d-inline-block align-top text-lg-left text-center" alt="Perfil Exotics" loading="lazy"> -->
            </div>
          </div>
          <div class="col-12 d-lg-none">
            <div class="imgProfile">
              <img
                :src="concesionario.photoURL"
                width="100%"
                class="d-inline-block align-top text-lg-left text-center"
                alt="Perfil Exotics"
                loading="lazy"
              />
              <!-- <img  src="../assets/mercedes-benz.jpg" width="100%" class="d-inline-block align-top text-lg-left text-center" alt="Perfil Exotics" loading="lazy"> -->
            </div>
          </div>
          <div
            class="col-lg-9 col-12 text-lg-left fontLightExotics py-5 py-lg-1"
          >
            <p class="subTitle mb-0">CONCESIONARIO</p>
            <h3 class="h3Exotics fontLightExotics mb-0">
              {{ concesionario.displayName | Capitalize }}
            </h3>
            <span class="fontLightExotics pr-2">{{ concesionario.city }}</span>
            <span class="fontLightExotics">-</span>
            <span class="fontLightExotics pl-2">
              {{ concesionario.phoneNumber }}
            </span>
            <!-- <a href="#">
                            <P class="textBtn text-left">CAMBIAR FOTO DE PERFIL</P>
                        </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="catalogoList">
      <div class="container">
        <div class="row">
          <!-- <div class=" col-2"></div> -->
          <div class="col-12 py-5">
            <ResultsList
              :vehicles="vehicles"
              :gridView="gridView"
              :listView="listView"
            ></ResultsList>
          </div>
          <!-- <div class="col-2 py-5">
                        <a href="">
                            <img src="../assets/subir-modelo-exotics-thumb.png" width="100%" class="d-inline-block align-top" alt="Subir modelo Exotics" loading="lazy">
                        </a>
                    </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import CardList from "../components/CardList.vue";
import Menu from "../components/Menu.vue";
import BusquedaResultados from "../components/SearchEngine/BusquedaResultados.vue";
import ResultsList from "../components/ResultsList.vue";
import Footer from "@/components/Footer.vue";
import vehicleService from "../services/vehicles";
import userService from "../services/users";
import marcaService from "../services/marcas";
import aniosService from "../services/Anios";
import ubicacionService from "../services/Ubicacion";
import categoriaService from "../services/Categorias";
import AliadosM from "../components/AliadosM.vue";
import concesionarioService from "../services/Concesionarios";

export default {
  name: "Catalogo",
  components: {
    Card,
    Menu,
    BusquedaResultados,
    ResultsList,
    CardList,
    Footer,
    AliadosM,
  },
  data() {
    return {
      vehiclesCount: 0,
      concesionario: "",
      concesionarioData: "",
      gridView: "",
      listView: "",
      concesionariosOptions: [],
      vehicles: [],
    };
  },
  watch: {
    // whenever question changes, this function will run
    filters(newSearchAdvance, oldSearchAdvance) {
      console.log("newSearchAdvance", newSearchAdvance);
      console.log("oldSearchAdvance", oldSearchAdvance);
    },
  },
  computed: {
    filters() {
      this.vehicles = [];
      this.vehicles = this.$store.state.filters;
    },
  },
  mounted() {
    this.gridView = true;
    this.id = this.$route.params.id;
    console.log(this.id);
    this.getUser(this.id);
    this.getConcesionario(this.id);
  },
  methods: {
    getVehicleType() {
      this.optionsCarroceria = [];
      const carroceria = this.optionsTipoVehicle.filter(
        (carroceria) => carroceria.label === this.formData.tipo_de_vehiculo
      );
      console.log(this.formData.tipo_de_vehiculo);
      this.optionsCarroceria = carroceria[0].carroceria;
    },
    showGrid(e) {
      e.preventDefault();
      this.gridView = true;
      this.listView = false;
    },
    showList(e) {
      e.preventDefault();
      this.gridView = false;
      this.listView = true;
    },
    getVehicles(limit) {
      vehicleService.getVehicles(limit).then((vehicles) => {
        this.vehiclesCount = vehicles.data.length;
        this.vehicles = vehicles.data;
      });
    },
    getConcesionario(id) {
      console.log(id);
      concesionarioService.getConcesionarioByName(id).then((concesionario) => {
        this.concesionario = concesionario;
        console.log("Este es ================= ", concesionario);
      });
    },
    getConcesionario(id) {
      console.log(id);
      concesionarioService.getConcesionarioByName(id).then((concesionario) => {
        this.concesionario = concesionario;
        console.log("Este es ================= ", concesionario);
      });
    },
    async getUser(id) {
      let vehiclesList;
      const concesionario = await vehicleService.getVehiclesByConcesionario(id);
      this.concesionarioData = concesionario.data;
      console.log("concesionario", concesionario);
      if (concesionario.data) {
        vehiclesList = concesionario.data;
      } else {
        vehiclesList = [];
      }
      this.vehicles = vehiclesList;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
