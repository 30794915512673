<template>
  <div class="financiarExotics">
    <Menu></Menu>
    <div class="financiarContainer py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12">
            <h2 class="h2Exotics text-left">
              FINANCIA <br />
              TU VEHÍCULO
            </h2>
          </div>
        </div>
        <div class="financiarForm pt-5">
          <div class="infoPersonalForm">
            <p class="subTitle text-left fontLightExotics">
              INFORMACIÓN PERSONAL
            </p>
            <div class="row">
              <div class="col-lg-3 col-6">
                <el-input
                  v-model="formData.firstName"
                  placeholder="Nombre"
                ></el-input>
              </div>
              <div class="col-lg-3 col-6">
                <el-input
                  v-model="formData.lastName"
                  placeholder="Apellido"
                ></el-input>
              </div>
              <div class="col-lg-3 col-6 mt-3 mt-lg-0">
                <el-select
                  v-model="formData.documento"
                  filterable
                  class="mb-4 w-100"
                  placeholder="Tipo de documento"
                >
                  <el-option
                    v-for="item in optionsId"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                    class=""
                  ></el-option>
                </el-select>
              </div>
              <div class="col-lg-3 col-6 mt-3 mt-lg-0">
                <el-input
                  v-model="formData.identificationNumber"
                  placeholder="Nº de identificación"
                ></el-input>
              </div>
            </div>
            <div class="row mt-lg-3">
              <div class="col-lg-3 col-12">
                <el-select
                  v-model="formData.ubicacion"
                  filterable
                  class="mb-4 w-100"
                  placeholder="Ubicación"
                >
                  <el-option
                    v-for="item in optionsUbicacion"
                    :key="item.id"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-6">
                <el-input
                  v-model="formData.email"
                  type="email"
                  placeholder="Email"
                ></el-input>
              </div>
              <div class="col-lg-3 col-6">
                <el-input
                  v-model="formData.phone"
                  placeholder="Celular"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="infoVehiculoForm pt-5">
            <p class="subTitle text-left fontLightExotics">
              INFORMACIÓN DE TU SOLICITUD
            </p>
            <div class="row">
              <div class="col-lg-3 col-6">
                <el-select
                  v-model="formData.marca"
                  @change="getMarca()"
                  filterable
                  class="mb-4 w-100"
                  placeholder="Marca"
                >
                  <el-option
                    v-for="item in optionsMarcas"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-lg-3 col-6">
                <el-select
                  v-model="formData.modelo"
                  filterable
                  class="mb-4 w-100"
                  placeholder="Modelo"
                >
                  <el-option
                    v-for="item in optionsModelos"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-lg-3 col-6">
                <el-select
                  v-model="formData.anio"
                  filterable
                  class="mb-4 w-100"
                  placeholder="Año"
                >
                  <el-option
                    v-for="item in optionsAnio"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-lg-3 col-6">
                <el-input
                  v-model="formData.amount"
                  placeholder="Monto a financiar"
                ></el-input>
              </div>
              <div class="col-lg-3 col-6">
                <el-checkbox
                  class="fontLightExotics"
                  v-model="formData.ingresos"
                  >¿Tus ingresos mensuales son superiores a
                  $1.500.000?</el-checkbox
                >
              </div>
            </div>
            <div class="row text-right flex-row-reverse py-lg-5">
              <div class="col-lg-2 col-12">
                <b-button
                  class="btn btn-primary btnPrimaryExotics fullWidthExotics"
                  @click="sendInfo()"
                  :href="`https://wa.me/+573053159933?text=Hola, Soy ${
                      formData.firstName
                    } ${
                      formData.lastName
                    }. Quiero financiar un vehículo de marca ${
                      formData.marca
                    }, modelo ${formData.modelo}, año ${
                      formData.anio
                    }, el monto a financiar es de ${formData.amount}, mis datos de contacto son: Cedula ${
                      formData.identificationNumber
                    }, email ${formData.email}, teléfono ${
                      formData.phone
                    }, ubicación ${formData.ubicacion}`"
                  >ENVIAR SOLICITUD</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <!-- <div class="financiarContainer py-5 d-lg-none">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="h2ExoticsMobile text-left">
                            FINANCIA TU VEHÍCULO
                        </h2>
                    </div>
                </div>
                <div class="financiarForm pt-5">
                    <div class="infoPersonalForm">
                        <p class="subTitle text-left fontLightExotics">INFORMACIÓN PERSONAL</p>
                        <div class="row">
                            <div class="col-6">
                                <el-input
                                    placeholder="Ej: BMW M3 Competition F80"
                                ></el-input>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="APELLIDO">
                            </div>
                            <div class="col-6 mt-3">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">TIPO DOCUMENTO</button>
                            </div>
                            <div class="col-6 mt-3">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="Nº IDENTIFICACIÓN">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">DEPARTAMENTO</button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">CIUDAD</button>
                            </div>
                            <div class="col-12 mt-3">
                                <input type="email" class="form-control busquedaAvanzadaInput outlineInput" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="CORREO ELECTRÓNICO">
                            </div>
                            <div class="col-12 mt-3">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="CELULAR">
                            </div>
                        </div>
                    </div>
                    <div class="infoVehiculoForm pt-5">
                        <p class="subTitle text-left fontLightExotics">INFORMACIÓN DEL VEHÍCULO</p>
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MARCA</button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">MODELO</button>
                            </div>
                            <div class="col-6 mt-3">
                                <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="VALOR DEL VEHÍCULO">
                            </div>
                            <div class="col-6 mt-3">
                                <button type="button" class="btn btn-outline-secondary dropdownFormExotics dropdown-toggle fullWidthExotics text-left">PLAZO</button>
                            </div>
                        </div>
                        <div class="row text-right flex-row-reverse py-5">
                            <div class="col-12">
                                <b-button class="btn btn-primary btnPrimaryExotics fullWidthExotics" href="">ENVIAR SOLICITUD</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <Recomendation class="d-none d-lg-block"></Recomendation>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import vehicleService from "../services/vehicles"
import marcaService from "../services/marcas"
import aniosService from "../services/Anios"
import ubicacionService from "../services/Ubicacion"
import categoriaService from "../services/Categorias"
import Menu from "../components/Menu.vue"
import Footer from "@/components/Footer.vue"
import Recomendation from "@/components/Recomendation.vue"
import EmailService from '@/services/Email'

export default {
  name: "Financiar",
  components: {
    Menu,
    Footer,
    Recomendation,
  },
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        amount: "",
        description: "",
        marca: "",
        modelo: "",
        ubicacion: "",
        precio: "",
        anio: "",
        kilometraje: "",
        tipo_de_vehiculo: "",
        carroceria: "",
        transmision: "",
        tipo_de_motor: "",
        motor: "",
        placas: "",
        version: "",
        potencia: "",
        kmh100: "",
        velocidad_max: "",
        numero_velocidades: "",
        frontal: "",
        direccion: "",
        puertas: "",
        blindaje: false,
        hibrido: false,
        garantia: false,
        lateral_1: "",
        lateral_2: "",
        posterior: "",
        interior: "",
        motor_foto: "",
        concesionario: "",
        estado: "",
        documento: "",
        identificationNumber: "",
        anio: "",
        ingresos: false,
      },
      imageUrlForntal: "",
      imageUrlLateral1: "",
      imageUrlLateral2: "",
      imageUrlPosterior: "",
      imageUrlMotor: "",
      imageUrlInterior: "",
      optionsEstados: [
        {
          label: "Nuevo",
          value: "nuevo",
        },
        {
          label: "Usado",
          value: "usado",
        },
      ],
      optionsId: [
        {
          label: "Cédula de Ciudadanía",
          value: "cedulac",
        },
        {
          label: "Cédula Extranjera",
          value: "cedulae",
        },
      ],
      optionsAnio: [
        {
          label: "2010",
          value: "2010",
        },
        {
          label: "2011",
          value: "2011",
        },
        {
          label: "2012",
          value: "2012",
        },
        {
          label: "2013",
          value: "2013",
        },
        {
          label: "2014",
          value: "2014",
        },
        {
          label: "2015",
          value: "2015",
        },
        {
          label: "2016",
          value: "2016",
        },
        {
          label: "2017",
          value: "2017",
        },
        {
          label: "2018",
          value: "2018",
        },
        {
          label: "2019",
          value: "2019",
        },
        {
          label: "2020",
          value: "2020",
        },
        {
          label: "2021",
          value: "2021",
        },
      ],
      optionsMarcas: [],
      optionsModelos: [],
      optionsTipoVehicle: [
        {
          label: "Carro",
          value: "carro",
          carroceria: [
            {
              label: "Sedán",
              value: "sedan",
            },
            {
              label: "Hatchback",
              value: "hatchback",
            },
            {
              label: "Convertible",
              value: "convertible",
            },
            {
              label: "Coupe",
              value: "coupe",
            },
          ],
        },
        {
          label: "Camioneta",
          value: "camioneta",
          carroceria: [
            {
              label: "SUV",
              value: "suv",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ],
        },
      ],
      optionsCarroceria: [
        {
          label: "Carro",
          value: "carro",
        },
        {
          label: "Camioneta",
          value: "camioneta",
        },
      ],
      optionsAnios: [],
      optionsPlacas: [
        {
          label: "Par",
          value: "par",
        },
        {
          label: "Impar",
          value: "impar",
        },
      ],
      optionsTransmision: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsTipoMotor: [
        {
          label: "Gasolina",
          value: "gasolina",
        },
        {
          label: "Diesel",
          value: "diesel",
        },
        {
          label: "Híbrido",
          value: "hibrido",
        },
        {
          label: "Eléctrico",
          value: "electrico",
        },
      ],
      optionsConcesionario: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsUbicacion: [],
    }
  },
  mounted() {
    // Everything is mounted and you can access the dropzone instance
    this.getMarcas()
    this.getAnios()
    this.getUbicacion()
  },
  methods: {
    async sendEmail() {
      const data = {
        subject: "Tienes un mensaje para financiar vehículo",
        templateID: 2672191,
        variables: {
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          docType: this.formData.documento,
          docId: this.formData.identificationNumber,
          location: this.formData.ubicacion,
          email: this.formData.email,
          phone: this.formData.phone,
          brand: this.formData.marca,
          model: this.formData.modelo,
          year: this.formData.anio,
          price: new Intl.NumberFormat().format(this.formData.amount),
          earnings: this.formData.ingresos ? 'Si' : 'No'
        },
      }
      //   console.log(data)
      await EmailService.sendEmail(data)
    },
    getVehicleType() {
      this.optionsCarroceria = []
      const carroceria = this.optionsTipoVehicle.filter(
        (carroceria) => carroceria.value === this.formData.tipo_de_vehiculo
      )
      this.optionsCarroceria = carroceria[0].carroceria
    },
    handlefotoSuccessFrontal(res, file) {
      console.log(res)
      this.imageUrlForntal = URL.createObjectURL(file.raw)
      window.localStorage.setItem("frontal", res.data)
    },
    handlefotoSuccessLateral1(res, file) {
      this.imageUrlLateral1 = URL.createObjectURL(file.raw)
      window.localStorage.setItem("lateral1", res.data)
    },
    handlefotoSuccessLateral2(res, file) {
      this.imageUrlLateral2 = URL.createObjectURL(file.raw)
      window.localStorage.setItem("lateral2", res.data)
    },
    handlefotoSuccessPosterior(res, file) {
      this.imageUrlPosterior = URL.createObjectURL(file.raw)
      window.localStorage.setItem("posterior", res.data)
    },
    handlefotoSuccessMotor(res, file) {
      this.imageUrlMotor = URL.createObjectURL(file.raw)
      window.localStorage.setItem("motor", res.data)
    },
    handlefotoSuccessInterior(res, file) {
      this.imageUrlInterior = URL.createObjectURL(file.raw)
      window.localStorage.setItem("interior", res.data)
    },
    beforefotoUpload(file) {
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error("La imagen debe estar en formato JPG!")
      }
      if (!isLt2M) {
        this.$message.error("La imagen excede los 2MB!")
      }
      return isJPG && isLt2M
    },
    sendInfo() {
      if (!this.formData.ingresos) {
        this.$alert(
          "Por favor llena los campos requeridos",
          "Campos requeridos",
          {
            confirmButtonText: "Intentar de nuevo",
          }
        )
      }
      this.sendEmail()
    },
    getMarcas() {
      marcaService.getMarcas().then((res) => {
        res.data.forEach((marca) => {
          this.optionsMarcas.push({
            key: marca.id,
            label: marca.name,
            value: marca.name,
          })
        })
      })
    },
    async getMarca() {
      const marcas = await marcaService.getMarcas()
      const marca = marcas.data.filter(
        (marca) => marca.name == this.formData.marca
      )
      const modelos = []

      marca[0].modelos.forEach((modelo) => {
        modelos.push({
          label: modelo,
          value: modelo,
        })
      })

      this.optionsModelos = modelos
    },
    getAnios() {
      aniosService.getAnios().then((res) => {
        res.data.forEach((anio) => {
          this.optionsAnios.push({
            label: anio.name,
            value: anio.name,
          })
        })
      })
    },
    getUbicacion() {
      ubicacionService.getUbicacion().then((res) => {
        res.data.forEach((ubicacion) => {
          this.optionsUbicacion.push({
            label: ubicacion.name,
            value: ubicacion.name,
          })
        })
      })
    },
  },
}
</script>

<style></style>
