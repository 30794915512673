import vehicleService from "../services/vehicles"
import marcaService from "../services/marcas"
import aniosService from "../services/Anios"
import ubicacionService from "../services/Ubicacion"
import categoriaService from "../services/Categorias"

export default {
  data() {
    return {
      optionsUbicacion: [],
      vehicles: [],
      optionsMarcas: [],
      optionsMarcasAdvance: [],
      optionsModelos: [],
      optionsAnios: [],
      formData: {
        name: "",
        description: "",
        marca: "",
        modelo: "",
        ubicacion: "",
        low_price: "",
        high_price: "",
        low_anio: "",
        high_anio: "",
        kilometraje: "",
        tipo_de_vehiculo: "",
        carroceria: "",
        transmision: "",
        tipo_de_motor: "",
        motor: "",
        placas: "",
        version: "",
        potencia: "",
        kmh100: "",
        velocidad_max: "",
        numero_velocidades: "",
        frontal: "",
        direccion: "",
        puertas: "",
        blindaje: false,
        hibrido: false,
        garantia: false,
        lateral_1: "",
        lateral_2: "",
        posterior: "",
        interior: "",
        motor_foto: "",
        concesionario: "",
        estado: "",
      },
      optionsEstados: [
        {
          label: "Nuevo",
          value: "nuevo",
        },
        {
          label: "Usado",
          value: "usado",
        },
      ],
      optionsTipoVehicle: [
        {
          label: "Carro",
          value: "carro",
          carroceria: [
            {
              label: "Sedán",
              value: "sedan",
            },
            {
              label: "Hatchback",
              value: "hatchback",
            },
            {
              label: "Convertible",
              value: "convertible",
            },
            {
              label: "Coupe",
              value: "coupe",
            },
          ],
        },
        {
          label: "Camioneta",
          value: "camioneta",
          carroceria: [
            {
              label: "SUV",
              value: "suv",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ],
        },
      ],
      optionsCarroceria: [
        {
          label: "Carro",
          value: "carro",
        },
        {
          label: "Camioneta",
          value: "camioneta",
        },
      ],
      optionsPlacas: [
        {
          label: "Par",
          value: "par",
        },
        {
          label: "Impar",
          value: "impar",
        },
      ],
      optionsTransmision: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsTipoMotor: [
        {
          label: "Gasolina",
          value: "gasolina",
        },
        {
          label: "Diesel",
          value: "diesel",
        },
        {
          label: "Híbrido",
          value: "hibrido",
        },
        {
          label: "Eléctrico",
          value: "electrico",
        },
      ],
      optionsConcesionario: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsPrice: [
        {
          label: "$0-$40.000.000",
          value: "40000000-60000000",
        },
        {
          label: "$40.000.000-$60.000.000",
          value: "60000000-80000000",
        },
        {
          label: "$60.000.000-$80.000.000",
          value: "80000000-100000000",
        },
        {
          label: "$80.000.000-$100.000.000",
          value: "100000000-150000000",
        },
        {
          label: "$100.000.000-$150.000.000",
          value: "150000000-200000000",
        },
        {
          label: "$150.000.000-$200.000.000",
          value: "200000000-250000000",
        },
        {
          label: "Más de $250.000.000",
          value: "250000000",
        },
      ],
      optionsKm: [
        {
          label: "Menos de 10.000km",
          value: "0-10000",
        },
        {
          label: "10.000km-30.000km",
          value: "10000-30000",
        },
        {
          label: "30.000km-50.000km",
          value: "30000-50000",
        },
        {
          label: "50.000km-70.000km",
          value: "50000-70000",
        },
        {
          label: "70.000km-100.000km",
          value: "70000-100000",
        },
        {
          label: "Más de 100.000km",
          value: "100000",
        },
      ],
    }
  },
  computed: {},
  mounted() {
    // this.getMarcas()
    this.getAnios()
    this.getUbicacion()
    this.getVehicles(0)
  },
  methods: {
    getVehicles(limit) {
      vehicleService.getVehicles(limit).then((vehicles) => {
        this.vehiclesCount = vehicles.data.length
        this.vehicles = vehicles.data
      })
    },
    // getMarcas() {
    //   marcaService.getMarcas().then((res) => {
    //     res.data.forEach((marca) => {
    //       this.optionsMarcas.push({
    //         key: marca.id,
    //         label: marca.name,
    //         value: marca.name,
    //       })
    //       this.optionsMarcasAdvance.push({
    //         key: marca.id,
    //         label: marca.name,
    //         value: marca.name,
    //       })
    //     })
    //   })
    // },
    // async getMarca() {
    //   const marcas = []
    //   marcas = await marcaService.getMarcas()
    //   const marca = marcas.data.filter(
    //     (marca) => marca.name == this.formData.marca
    //   )
    //   const modelos = []

    //   marca[0].modelos.forEach((modelo) => {
    //     modelos.push({
    //       label: modelo,
    //       value: modelo,
    //     })
    //   })

    //   this.optionsModelos = modelos
    // },
    getAnios() {
      aniosService.getAnios().then((res) => {
        res.data.forEach((anio) => {
          this.optionsAnios.push({
            label: anio.name,
            value: anio.name,
          })
        })
      })
    },
    getUbicacion() {
      ubicacionService.getUbicacion().then((res) => {
        res.data.forEach((ubicacion) => {
          this.optionsUbicacion.push({
            label: ubicacion.name,
            value: ubicacion.name,
          })
        })
      })
    },

    getVehiclesByFilters() {
      var query = {}
      if (this.formData.estado) query.estado = this.formData.estado
      if (this.formData.marca) query.marca = this.formData.marca
      if (this.formData.modelo) query.modelo = this.formData.modelo
      if (this.formData.ubicacion) query.ubicacion = this.formData.ubicacion
      if (this.formData.low_price) query.low_price = this.formData.low_price
      if (this.formData.high_price) query.high_price = this.formData.high_price
      if (this.formData.anio) query.anio = this.formData.low_anio
      if (this.formData.anio) query.anio = this.formData.high_anio
      if (this.formData.low_km) query.low_km = this.formData.low_km
      if (this.formData.high_km) query.high_km = this.formData.high_km
      if (this.formData.tipo_de_carro)
        query.tipo_de_carro = this.formData.tipo_de_carro
      if (this.formData.carroceria) query.carroceria = this.formData.carroceria
      if (this.formData.transmision)
        query.transmision = this.formData.transmision
      if (this.formData.tipo_de_motor)
        query.tipo_de_motor = this.formData.tipo_de_motor
      if (this.formData.placas) query.placas = this.formData.placas
      if (this.formData.concesionario)
        query.concesionario = this.formData.concesionario
      // const dataFilter = `marca=${this.formData.marca}&modelo=${this.formData.modelo}&ubicacion=${this.formData.ubicacion}&precio=${this.formData.precio}&anio=${this.formData.anio}&kilometraje=${this.formData.kilometraje}&tipo_de_vehiculo=${this.formData.tipo_de_vehiculo}&carroceria=${this.formData.carroceria}&transmision=${this.formData.transmision}&tipo_de_motor=${this.formData.tipo_de_motor}&placa=${this.formData.placas}&concesionario=${this.formData.concesionario}`
      window.localStorage.setItem("filter", JSON.stringify(query))
      this.$router.push("/results")
      vehicleService.getVehiclesByFilters(query).then(async (vehicles) => {
        this.vehicles = []
        this.vehiclesCount = vehicles.length
        this.vehicles = vehicles.data
        const result = await this.$store.dispatch("filters", vehicles.data)
      })
    },
    search(word) {
      vehicleService.search(word).then((vehicles) => {
        console(vehicles)
        this.vehiclesCount = vehicles.length
        this.vehicles = vehicles
      })
    },
  },
}
