import axios from "axios";
import configService from "../../configs/config";

const userService = {};
userService.getUsers = (limit = 100, token) => {
  return axios
    .get(`${configService.apiUrl}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(error => error);
};

userService.getUser = (id) => {
  return axios
    .get(`${configService.apiUrl}/concesionarios/${id}`)
    .then(res => res.data)
    .catch(error => error);
};

userService.createUser = async data => {
  return axios
    .post(`${configService.apiUrl}/users`, data)
    .then(res => res.data)
    .catch(error => console.log(error));
};

userService.userAuthenticate = async data => {
  console.log(data)
  await axios
    .post(`${configService.apiUrl}/users/authentication`, data)
    .then(res => {
      console.log("res => ", res)
      return res
    })
    .catch(error => {
      console.log("error.Error => ", error)
    });
};

userService.updateUser = ( id, data) => {
  console.log(data)
  return axios
    .put(`${configService.apiUrl}/concesionarios/${id}`, data)
    .then(res => {
      console.log(res)
      return res
    })
    .catch(error => {
      console.log(error)
      return error
    });
};

userService.removeUser = id => {
  return axios
    .delete(`${configService.apiUrl}/users/${id}`)
    .then(res => res.data)
    .catch(error => error);
};

export default userService;
