<template>
  <div class="home">
    <Menu></Menu>
    <BusquedaForm v-if="!searchHide"></BusquedaForm>
    <BusquedaAvanzada v-if="searchHide"></BusquedaAvanzada>
    <!-- desktop version destacados -->
    <Destacados class="d-none d-lg-block"></Destacados>
    <!-- mobile version destacados -->
    <div class="pl-3 d-lg-none">
        <h4 class="h3ExoticsMobile text-left  mt-4">+ DESTACADOS</h4>
        <div class="scrollX">
            <CardDestacadosM
                :vehicle='vehicle'
                v-for="vehicle in vehicles"
                class="cardDestacadosMobile d-lg-none"
                fontSize="12px"
            ></CardDestacadosM>
        </div>
        <div class="pr-3">
            <router-link
                class="btn btn-outline-secondary btnOutSecExotics mt- fullWidthExotics mb-5"
                href="/vehicles"
                >VER CATÁLOGO COMPLETO
            </router-link>
        </div>
    </div>
    <!-- desktop version propiedades -->
    <!-- <div class="propiedades d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-7 propiedadesDestacados">
                    <h4 class="h3Exotics text-left pb-4 fontLightExotics mt-5">+ PROPIEDADES MÁS RECIENTES</h4>
                    <div class="row">
                        <CardDestacados :vehicle="vehicle" color="#fff" fontSize="12px"></CardDestacados>
                        <CardDestacados :vehicle="vehicle" color="#fff" fontSize="12px"></CardDestacados>
                        <CardDestacados :vehicle="vehicle" color="#fff" fontSize="12px"></CardDestacados>
                    </div>
                </div>
                <div class="col-5">
                    <div class="d-flex align-items-end h-100">
                        <div class="text-left pb-5">
                            <h2 class="h2Exotics fontLightExotics text-left">UN ESTILO <br>DE VIDA EXITOSO</h2>
                            <button class="btn btn-primary btnPrimaryExotics mt-2" type="submit">VER TODAS LAS OPCIONES</button>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- mobile version propiedades -->
    <!-- <div class="pl-3 d-lg-none">
        <h4 class="h3ExoticsMobile text-left mt-5">+ PROPIEDADES MÁS RECIENTES</h4>
        <div class="scrollX">
            <CardDestacadosM
                :data="vehicle"
                v-for="vehicle in vehicles"
                class="cardDestacadosMobile"
                fontSize="12px"
            ></CardDestacadosM>
        </div>
        <div class="pr-3">
            <button class="btn btn-outline-secondary btnOutSecExotics mt-2 fullWidthExotics mb-5" type="submit">VER TODAS LAS OPCIONES</button>
        </div>
    </div> -->
    <!-- eds propiedades -->
    <div class="homeActions d-none d-lg-block">
        <div class="container py-5">
            <div class="row text-left">
                <div class="col-4">
                    <a href="/financiar" class="text-left">
                        <img src="../assets/financiar-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
                <div class="col-4">
                    <a :href="`${auth? '/subir-modelo': '/publicar'}`" class="text-left">
                        <img src="../assets/publicar-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
                <div class="col-4">
                    <a href="/asesoramos">
                        <img src="../assets/asesoramos-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="homeActions d-lg-none">
        <div class="container py-5">
            <div class="scrollX text-left">
                <div class="scrollXItem">
                    <a href="/financiar" class="text-left">
                        <img src="../assets/financiar-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
                <div class="scrollXItem">
                    <a :href="`${auth ? '/subir-modelo':'/publicar'}`" class="text-left">
                        <img src="../assets/publicar-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
                <div class="scrollXItem">
                    <a href="/asesoramos">
                        <img src="../assets/asesoramos-home-back-exotics.png" width="100%" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- desktop version aliados -->
    <div class="aliados d-none d-lg-block">
        <div class="container py-5">
            <h4 class="h3Exotics text-left pb-5">+ CONCESIONARIOS ALIADOS</h4>
            <div class="row pb-5">
                <div class="col-3 center-component">
                  <div v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_1.png')})` }" >
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_2.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div class="bg-content" v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_3.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_4.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_5.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div class="bg-content" v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_6.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div class="bg-content bg-white" v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_7.png')})` }" > 
                  </div>
                </div>
                <div class="col-3 center-component">
                  <div class="bg-white" v-bind:style="{ backgroundImage: `url(${require('../assets/concessionaire/concessionaire_8.png')})` }" > 
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- mobile version aliados -->
    <AliadosM></AliadosM>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import Menu from '@/components/Menu.vue'
import BusquedaForm from '../components/SearchEngine/BusquedaForm.vue'
import BusquedaAvanzada from '../components/SearchEngine/BusquedaAvanzada.vue'
import Footer from '@/components/Footer.vue'
import Destacados from '../components/Destacados.vue'
import CardDestacados from '../components/CardDestacados.vue'
import CardDestacadosM from '../components/CardDestacadosM.vue'
import AliadosM from '../components/AliadosM.vue'
import vehiclesService from "../services/vehicles"
import marcaService from "../services/marcas";
import aniosService from "../services/Anios";
import ubicacionService from "../services/Ubicacion";
import concesionarioService from "../services/Concesionarios";


export default {
  name: 'Home',
  components: {
    Menu,
    BusquedaForm,
    BusquedaAvanzada,
    Footer,
    Destacados,
    CardDestacados,
    CardDestacadosM,
    AliadosM
  },
  data() {
    return {
      auth: false,
      slide: 0,
      sliding: null,
      vehicles: [],
      searchHide: true,
      optionsUbicacion: [],
      optionsMarcas: [],
      optionsModelos: [],
      formData: {
        name: "",
        description: "",
        marca: "",
        modelo: "",
        ubicacion: "",
        precio: "",
        anio: "",
        kilometraje: "",
        tipo_de_vehiculo: "",
        carroceria: "",
        transmision: "",
        tipo_de_motor: "",
        motor: "",
        placas: "",
        version: "",
        potencia: "",
        kmh100: "",
        velocidad_max: "",
        numero_velocidades: "",
        frontal: "",
        direccion: "",
        puertas: "",
        blindaje: false,
        hibrido: false,
        garantia: false,
        lateral_1: "",
        lateral_2: "",
        posterior: "",
        interior: "",
        motor_foto: "",
        concesionario: "",
        estado: "",
      },
      optionsEstados: [
        {
          label: "Nuevo",
          value: "nuevo",
        },
        {
          label: "Usado",
          value: "usado",
        },
      ],
      optionsTipoVehicle: [
        {
          label: "Carro",
          value: "carro",
          carroceria: [
            {
              label: "Sedán",
              value: "sedan",
            },
            {
              label: "Hatchback",
              value: "hatchback",
            },
            {
              label: "Convertible",
              value: "convertible",
            },
            {
              label: "Coupe",
              value: "coupe",
            },
          ],
        },
        {
          label: "Camioneta",
          value: "camioneta",
          carroceria: [
            {
              label: "SUV",
              value: "suv",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ],
        },
      ],
      optionsCarroceria: [
        {
          label: "Carro",
          value: "carro",
        },
        {
          label: "Camioneta",
          value: "camioneta",
        },
      ],
      optionsPlacas: [
        {
          label: "Par",
          value: "par",
        },
        {
          label: "Impar",
          value: "impar",
        },
      ],
      optionsTransmision: [
        {
          label: "Manual",
          value: "manual",
        },
        {
          label: "Automático",
          value: "automatico",
        },
      ],
      optionsTipoMotor: [
        {
          label: "Gasolina",
          value: "gasolina",
        },
        {
          label: "Diesel",
          value: "diesel",
        },
        {
          label: "Híbrido",
          value: "hibrido",
        },
        {
          label: "Eléctrico",
          value: "electrico",
        },
      ],
      optionsConcesionario: [],
      concesionarios: ""
    }
  },
  watch: {
    // whenever question changes, this function will run
    showAdvance(newSearchAdvance, oldSearchAdvance){}
  },
  mounted() {
    this.getConcesionarios()
    this.getDatavuex()
    this.getVehicles(4)
    
    const user = window.localStorage.getItem("SET_USER")
    if (user &&   user != '') {
      this.auth = true
    } else {
      this.auth = false
    }
    
  },
  computed: {
    showAdvance() {
        this.searchHide = this.$store.state.searchHide
    } ,
    ...mapGetters("search", ["getformDataStore"]),
  },
  methods: {
  getDatavuex() {
    this.formData = this.getformDataStore;
  },
    getConcesionarios() {
      concesionarioService.getConcesionarios().then((res) => {
        this.concesionarios = res.data
        res.data.forEach((concesionarios) => {
          this.optionsConcesionario.push({
            label: concesionarios.displayName,
            value: concesionarios.displayName,
          });
        });
      });
    }, 
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    getVehicleType() {
      this.optionsCarroceria = [];
      const carroceria = this.optionsTipoVehicle.filter(
        (carroceria) => carroceria.label === this.formData.tipo_de_vehiculo
      );
      this.optionsCarroceria = carroceria[0].carroceria;
    },
    getVehicles(limit) {
      console.log('ENtró papá')
      vehiclesService.getVehicles(limit).then((vehicles) => {
        console.log("vehicles Mobile", vehicles)
        this.vehiclesCount = vehicles.data.length
        this.vehicles = vehicles.data
      })
    },
    getMarcas() {
      marcaService.getMarcas().then((res) => {
        res.data.forEach((marca) => {
          this.optionsMarcas.push({
            key: marca.id,
            label: marca.name,
            value: marca.name,
          });
        });
      });
    },
    async getMarca() {
      const marcas = await marcaService.getMarcas()
      const marca = marcas.data.filter(marca => marca.name == this.formData.marca)
      const modelos = []

      marca[0].modelos.forEach(modelo => {
        modelos.push({
          label: modelo,
          value: modelo
        })
      })

      this.optionsModelos = modelos
    },
    getAnios() {
      aniosService.getAnios().then((res) => {
        res.data.forEach((anio) => {
          this.optionsAnios.push({
            label: anio.name,
            value: anio.name,
          });
        });
      });
    },
    getUbicacion() {
      ubicacionService.getUbicacion().then((res) => {
        res.data.forEach((ubicacion) => {
          this.optionsUbicacion.push({
            label: ubicacion.name,
            value: ubicacion.name,
          });
        });
      });
    },

    getVehiclesByFilters() {
      var query = {}
       if (this.formData.estado) query.estado = this.formData.estado
      if (this.formData.marca) query.marca = this.formData.marca
      if (this.formData.modelo) query.modelo = this.formData.modelo
      if (this.formData.ubicacion) query.ubicacion = this.formData.ubicacion
      if (this.formData.low_price) query.low_price = this.formData.low_price
      if (this.formData.high_price) query.high_price = this.formData.high_price
      if (this.formData.anio) query.anio = this.formData.anio
      if (this.formData.low_km) query.low_km = this.formData.low_km
      if (this.formData.kilometraje) query.kilometraje = this.formData.kilometraje
      if (this.formData.high_km) query.high_km = this.formData.high_km
      if (this.formData.tipo_de_carro) query.tipo_de_carro = this.formData.tipo_de_carro
      if (this.formData.carroceria) query.carroceria = this.formData.carroceria
      if (this.formData.transmision) query.transmision = this.formData.transmision
      if (this.formData.tipo_de_motor) query.tipo_de_motor = this.formData.tipo_de_motor
      if (this.formData.placas) query.placas = this.formData.placas
      if (this.formData.concesionario) query.concesionario = this.formData.concesionario
      // const dataFilter = `marca=${this.formData.marca}&modelo=${this.formData.modelo}&ubicacion=${this.formData.ubicacion}&precio=${this.formData.precio}&anio=${this.formData.anio}&kilometraje=${this.formData.kilometraje}&tipo_de_vehiculo=${this.formData.tipo_de_vehiculo}&carroceria=${this.formData.carroceria}&transmision=${this.formData.transmision}&tipo_de_motor=${this.formData.tipo_de_motor}&placa=${this.formData.placas}&concesionario=${this.formData.concesionario}`
      vehiclesService
        .getVehiclesByFilters(query)
        .then( async (vehicles) => {
          this.vehicles=[]
          this.vehiclesCount = vehicles.length
          this.vehicles = vehicles.data
          const result = await this.$store.dispatch('filters', vehicles.data)
        })
    },
    search(word) {
      vehiclesService.search(word).then((vehicles) => {
        this.vehiclesCount = vehicles.length
        this.vehicles = vehicles
      })
    }
  }
}
</script>
<style>
.center-component{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.center-component > div.bg-content{
    background-size: contain;
    background-repeat: no-repeat;
}


.center-component > div.bg-white{
  background-color: white;
}

.center-component > div{
  width: 150px;
  height: 150px;
  background-color: black;
  background-size: cover;
  background-position: center;
}

</style>
