'use strict'
import Axios from 'axios'
import configService from "../../configs/config"

export default {
  namespaced: true,
  state: {
    user: null
  },
  actions: {
    login({ commit }, payload){
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await Axios.post(`${configService.apiUrl}/users/authentication`, payload)
          localStorage.setItem('SET_USER', JSON.stringify(data))
          commit('SET_USER', data)
          resolve(data)
        }catch(e){
          reject(e)
        }
      })
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    }
  },
  getters: {
    user: state => state.user
  }
}