import Vue from "vue";

export const FILTERS = {
  Capitalize: (value) => {
    // console.log("Capitalize!!!!");
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
};

