<template>
    <div class="recomendationsLogin py-5">
        <div class="container">
            <div class="row">
                <div class="col-4 text-center">
                    <img src="../assets/login-recomendations1-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                    <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">Te obsequiamos beneficios exclusivos como una tarjeta EXOTICSCO al comprar tu vehículo con nosotros
</p>
                    <a href="https://wa.me/+573053159933" target="_blank">
                        <b-button class="btn-primary btnPrimaryExotics">CONTACTAR</b-button>
                    </a>
                </div>
                <div class="col-4 text-center">
                    <img src="../assets/login-recomendations2-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                    <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">Encuentra el inventario más importante de vehículos alta gama del país</p>
                    <a href="/vehicles">
                        <b-button class="btn-primary btnPrimaryExotics">VER CATÁLOGO</b-button>
                    </a>
                </div>
                <div class="col-4 text-center">
                    <img src="../assets/login-recomendations5-exotics.jpg" width="100%" class="d-inline-block align-top" alt="Perfil Exotics" loading="lazy">
                    <p class="font-weight-bolder text-uppercase fontLightExotics pt-4">Contactanos para ayudarte <br>en la compra o venta <br>de tu vehiculo</p>
                    <a href="/asesoramos">
                        <b-button class="btn-primary btnPrimaryExotics">TE ASESORAMOS</b-button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Recomendation',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
