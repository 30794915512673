<template>
<div class="aliadosMobile d-lg-none pt-4">
    <div class="container">
        <h4 class="h3ExoticsMobile text-left pb-5 fontLightExotics">+ CONCESIONARIOS ALIADOS</h4>
        <div class=" pb-5 scrollX">
            <div class="col-6 scrollXItem" v-for="concesionario in concesionarios">
                <img v-if="concesionario.photoURL" :src="`${concesionario.photoURL}`" width="150" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
                <img v-else src="../assets/aliado-exotics-logo.png" width="150" class="d-inline-block align-top" alt="Financiar Exotics" loading="lazy">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import concesionarioService from "../services/Concesionarios";

export default {
  name: 'AliadosM',
  data() {
      return {
          concesionarios: ""
      }
  },
  mounted() {
      this.getConcesionarios()
  },
  methods: {
      getConcesionarios() {
      concesionarioService.getConcesionarios().then((res) => {
        console.log("concesionarios", res.data)
        this.concesionarios = res.data
      });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>