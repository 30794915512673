<template>
    <div class="loginFormExotics">
        <p class="fontLightExotics text-left">Sección exclusiva para concesionarios aliados de Exotics Co</p>
        <input type="text" class="form-control busquedaAvanzadaInput outlineInput" placeholder="USUARIO">
        <input type="password" class="form-control busquedaAvanzadaInput outlineInput mt-3" id="exampleInputPassword1" placeholder="CONTRASEÑA">
        <div class="d-flex justify-content-between align-items-center py-3">
            <div class="">
                <a @click="hideForm"><P class="textBtnBorder fontLightExotics text-left mb-0">REGISTRARME</P></a>
            </div>
            <div class="">
                <a @click="hideForm"><P class="textBtnBorder fontLightExotics text-left mb-0">OLVIDÉ MI CONTRASEÑA</P></a>
            </div>
            <div>
                <button class="btn btn-primary btnPrimaryExotics" type="submit">ENTRAR ></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
      return {
        hide: false
      }
  },
  methods: {
      hideForm(){
          this.hide = true
          window.localStorage.setItem('hide', this.hide)
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
