import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ElementUI from 'element-ui'
import VueCurrencyFilter from "vue-currency-filter"
import VueNumeric from 'vue-numeric'
import VuePaginate from "vue-paginate";
import {registerFilters} from "./filters"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style.css'

registerFilters()
// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(ElementUI)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VuePaginate)
Vue.use(VueNumeric)

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: false
})

Vue.config.productionTip = false
Vue.config.debug = true; 
Vue.config.devtools = true;

new Vue({
  router,
  store,
  linkActiveClass: "active",
  render: h => h(App)
}).$mount('#app')
