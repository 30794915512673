<template>
  <div class="asesoramosExotics">
    <Menu></Menu>
    <!-- desktop version -->
    <div class="asesoramosContainer py-5 ">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2Exotics text-left mb-4">
              TÉRMINOS Y CONDICIONES
            </h2>
            <p class="text-left fontLightExotics fw-light">
              Este documento describe los términos y condiciones ("Términos y
              Condiciones") aplicables al uso de los servicios ofrecidos por
              Exotics Colombia Autos (Registrada ante la Superintendencia de
              Industria y Comercio con Referente N 2D2019/0053236) dentro del
              sitio www.exotisco.com (el "Sitio"). Mediante el uso de este Sitio
              cualquier persona natural o jurídica o entidades de cualquier
              naturaleza (en adelante "Usuario") está indicando su aceptación a
              estos Términos y Condiciones. El sitio puede modificar estos
              Términos y Condiciones en cualquier momento, usted debe visitar
              esta página periódicamente para revisar los Términos y
              Condiciones.
              <br /><br />
              Cualquier persona que no acepte estos Términos y Condiciones, los
              cuales tienen un carácter obligatorio y vinculante, deberá
              abstenerse de utilizar el sitio y/o los servicios.
              <br /><br />
              Capacidad: Los Servicios sólo están disponibles para personas que
              tengan capacidad legal para contratar. No podrán utilizar los
              servicios las personas que no tengan esa capacidad, los menores de
              edad o Usuarios que hayan sido suspendidos temporalmente o
              inhabilitados definitivamente. Si estás inscribiendo un Usuario
              como Empresa, debes tener capacidad para contratar a nombre de tal
              entidad y de obligar a la misma en los términos de este Acuerdo.
              <br /><br />
              Publicación de anuncios: La publicación de anuncios en el Sitio
              podrá ser realizada directamente por el Usuario, franquiciados o
              administradores del Sitio, en cuyo caso la publicación se hará por
              cuenta y orden de éstos y siguiendo sus instrucciones en cuanto a
              información, modalidad, etc. En todos los anuncios, el Usuario
              deberá declarar, brindar y publicar toda la información relativa a
              el bien ofrecido de manera exacta, precisa y verdadera, asumiendo
              el compromiso de actualizarla conforme resulte necesario. Exotics
              Colombia Autos no se responsabiliza por la certeza de la
              información provista por el Usuario, incluida la información
              relativa a sus datos personales y al bien ofrecido. El Usuario
              será quien garantiza y responde, en cualquier caso, de la
              veracidad, exactitud, vigencia y autenticidad de todos los datos
              ingresados.
              <br /><br />
              El contenido de este Web Site, incluyendo, pero sin limitarse a,
              los textos, gráficas, imágenes, logotipos, iconos, software y
              cualquier otro material (el "Material") están protegidos bajo las
              leyes de derechos de autor, leyes de propiedad industrial y otras
              leyes aplicables. Todo el Material es de propiedad de la Exotics
              Colombia Autos o de sus Usuarios. El uso no autorizado del
              material puede constituir una violación de las leyes colombianas o
              extranjeras sobre derechos de autor, leyes de propiedad industrial
              u otras leyes. Usted no podrá vender o modificar el Material en
              manera alguna, ni ejecutar o anunciar públicamente el Material ni
              distribuirlo para propósitos comerciales. Usted no podrá copiar o
              adaptar el código HTML que la Compañía crea para generar sus
              páginas ya que el mismo está protegido por los derechos de autor
              de la Compañía.
              <br /><br />
              Los anuncios podrán incluir material pertinentes para la venta del
              bien, siempre que éstos no violen ninguna ley o disposición de
              este acuerdo. El bien ofrecido por el Usuario debe ser
              apropiadamente descrito en cuanto a sus condiciones y
              características relevantes. En caso de que se incluya una
              fotografía, esta deberá corresponder específicamente al bien que
              se ofrece. Se entiende y presume que, mediante la inclusión del
              bien en el Sitio, el Usuario acepta que tiene la intención y el
              derecho de venderlo, o está facultado para ello por su titular. Se
              establece que los precios de los bienes publicados deberán ser
              expresados en moneda del curso legal y con todos los impuestos
              incluidos cuando corresponda la aplicación de éstos. En caso de
              que se detecte una infracción a cualquiera de las disposiciones
              establecidas en esta cláusula Exotics Colombia Autos podrá editar
              el espacio o dar de baja la publicación donde se encuentre la
              infracción.
               <br /><br />
              Derecho de Admisión: Exotics Colombia Autos se reserva el derecho
              de no publicar aquellos avisos que no cumplan con los parámetros
              establecidos por Exotics Colombia Autos en cuanto a tipos y
              clasificación de carros, fotografías defectuosas, fuera de ángulo,
              fotografías rechazadas, así como también a editar datos que
              contradigan las políticas del Sitio. También Exotics Colombia
              Autos podrá suspender cualquier publicación que estime
              conveniente, sin previo aviso o notificación.

              <br /><br />
              Tarifas: La publicación de anuncios en Exotics Colombia
              Autos tiene un costo. Al publicar un bien para la venta, el
              Usuario acepta pagar la tarifa correspondiente a los planes que le
              son ofrecidos. Dicho valor debe ser pagado únicamente en forma de
              dinero en moneda Colombiana (consignación, transferencia,
              efectivo) antes de ser publicado. Los planes ofrecidos
              corresponden al Plan Básico con un precio de COP $60.000 que
              incluye una (1) publicación (no destacada) en este sitio web,
              seis(6) historias y tres (3) publicaciones en nuestro Instagram
              (@ExoticsCo_Autos) y Plan Premium con un precio de COP $85.000 que
              incluye (1) publicación (destacada por al menos un día) en este
              sitio web, nueve (9) historias y seis (6) publicaciones en nuestro
              Instagram (@ExoticsCo_Autos) y una (1) publicación en Instagram
              (@Exotics_Colombia). Una vez pagado no se hará devolución del
              dinero

              <br /><br />
              Término:El término de la publicación es de tres meses. Sin
              embargo, el usuario deberá notificar a Exotics Colombia Autos si
              desea continuar con el servicio para pagar nuevamente la tarifa,
              caso contrario y una vez transcurrido este plazo Exotics Colombia
              Autos  se reserva el derecho de suspender la publicación sin
              previo aviso. Exotics Colombia Autos no asume obligación de
              notificar al contratante la fecha de vencimiento de la
              publicación, sin perjuicio de ello, en caso de que lo notifique no
              se entenderá que ello genera obligación de seguir haciéndolo. Es
              deber del usuario verificar la continuidad de su aviso en el
              Sitio.
              <br /><br />
              Responsabilidad: Exotics Colombia Autos sólo pone a disposición de
              los Usuarios un espacio virtual que permite la publicación de
              anuncios, Exotics Colombia Autos no es el propietario de los
              bienes ofrecidos, por lo cual no interviene en el
              perfeccionamiento de las operaciones realizadas entre los Usuarios
              ni en las condiciones por ellos estipuladas para las mismas, por
              ello no será responsable de la solvencia de las partes, el
              cumplimiento efectivo de las obligaciones, o estado de los bienes
              a transferir.Exotics Colobia Autos tampoco se hace responsables
              por la validez del negocio ni respecto de la existencia, calidad,
              cantidad, estado, integridad o legitimidad de los bienes
              ofrecidos, adquiridos o enajenados por los Usuarios, así como de
              la veracidad de los datos personales por ellos ingresados. Cada
              Usuario conoce y acepta ser el exclusivo responsable por los
              bienes que anuncia para su venta y por la forma de llevar adelante
              una transacción.
              <br /><br />
              Exotics Colombia Autos no asume ninguna responsabilidad por la
              información contenida en los anuncios, ya que la misma ha sido
              suministrada y corroborada por el Usuario contratante. Asimismo, 
              se aclara que Exotis Colombia Autos no será responsable por
              errores, interpretaciones erradas o por la precisión de la
              información parcial o total publicada. Es responsabilidad del
              Usuario que contrató el servicio de publicación de aviso
              clasificado verificar que los datos contenidos en el aviso sean
              correctos y estén actualizados.
              <br /><br />
              Debido a que Exotics Colombia Autos no tiene ninguna participación
              durante todo el tiempo en que el bien se anuncia para la venta, ni
              en la posterior negociación y perfeccionamiento del contrato
              definitivo entre las partes, no será responsable por el efectivo
              cumplimiento de las obligaciones asumidas por los Usuarios en el
              perfeccionamiento de la operación. El Usuario conoce y acepta que
              al realizar operaciones con otros Usuarios o terceros lo hace bajo
              su propio riesgo. En ningún caso Exotics Colombia Autos será
              responsable por lucro cesante, o por cualquier otro daño y/o
              perjuicio que haya podido sufrir el Usuario, debido a las
              operaciones realizadas o no realizadas por bienes anunciados a
              través de Exotics Colombia Autos. Tampoco será responsable por la
              realización de operaciones con otros Usuarios basadas en la
              confianza depositada en el sistema o los Servicios brindados
              por Exotics Colombia Autos
              <br /><br />
              Exotics Colombia  recomienda actuar con prudencia y sentido común
              al momento de realizar operaciones con otros Usuarios, verificando
              el estado y legitimitad de los bienes. El Usuario debe tener
              presente los riesgos de contratar con menores o con personas que
              se valgan de una identidad falsa. Exotics Colombia Autos no será
              responsable por la realización de operaciones con otros Usuarios
              basadas en la confianza depositada en el sistema o los Servicios
              brindados.
              <br /><br />
              En caso de que uno o más Usuarios o algún tercero inicie cualquier
              tipo de reclamo o acciones legales contra otro Usuario, todos y
              cada uno de los Usuarios involucrados en dichos reclamos o
              acciones eximen de toda responsabilidad a Exotics Colombia Autos 
              y a sus directores, gerentes, empleados, franquiciados, operarios,
              representantes y apoderados.
              <br /><br />
              Alcance de los Servicios: Este acuerdo no crea ningún contrato de
              sociedad, de mandato, de franquicia, o relación laboral
              entre Exotics Colombia Autos y el Usuario. El Usuario reconoce y
              acepta que Exotics Colombia Autos no es parte en ninguna
              operación, ni tiene control alguno sobre la calidad, seguridad o
              legalidad de los bienes anunciados, la veracidad o exactitud de
              los anuncios, la capacidad de los Usuarios para vender o comprar
              bienes.
              <br /><br />
              Exotics Colombia Autos no puede asegurar que un Usuario completará
              una operación ni podrá verificar la identidad o datos personales
              ingresados por los Usuarios. Exotics Colombia Autosno garantiza la
              veracidad de la publicidad de terceros que aparezca en el sitio y
              no será responsable por la correspondencia o contratos que el
              Usuario celebre con dichos terceros o con otros Usuarios.

              <br /><br />
              Fallas en el sistema: Exotics Colombia no se responsabiliza por
              ningún daño, perjuicio o pérdida sufrida por el Usuario, causados
              por fallas en el sistema, en el servidor o en Internet. Exotics
              Colombia Autos tampoco será responsable por ningún virus que
              pudiera infectar el equipo del Usuario como consecuencia del
              acceso, uso o examen de su sitio web o a raíz de cualquier
              transferencia de datos, archivos, imágenes, textos, o audio
              contenidos en el mismo.
              <br /><br />
              Los Usuarios no podrán imputarle responsabilidad alguna ni exigir
              pago por lucro cesante, en virtud de perjuicios resultantes de
              dificultades técnicas o fallas en los sistemas o en
              Internet. Exotics Colombia Autos no garantiza el acceso y uso
              continuo o ininterrumpido de su sitio.
              <br /><br />
              El sistema puede eventualmente no estar disponible debido a
              dificultades técnicas o fallas de Internet, o por cualquier
              circunstancia ajena a Exotics Colombia Autos; en tales casos, se
              procurará restablecerlo con la mayor celeridad posible sin que por
              ello pueda imputársele algún tipo de responsabilidad. Exotics
              Colombia Autos no será responsable por ningún error u omisión
              contenidos en su sitio web.
              <br /><br />
              Privacidad: Para utilizar los Servicios ofrecidos por Exotics
              Colombia Autos, los Usuarios deberán facilitar determinados datos
              de carácter personal. Su información personal se procesa y
              almacena en servidores o medios magnéticos que mantienen altos
              estándares de seguridad y protección tanto física como
              tecnológica. Una vez registrado el Sitio, Exotics Colombia Autos
              no venderá, alquilará o compartirá la información personal excepto
              en las formas establecidas en sus políticas. Puede suceder que, en
              virtud de órdenes judiciales, o de regulaciones legales, nos
              veamos compelidos a revelar información a las autoridades o
              terceras partes bajo ciertas circunstancias, o bien en casos que
              terceras partes puedan interceptar o acceder a cierta información
              o transmisiones de datos en cuyo caso Exotics Colombia Autos no
              responderá por la información que sea revelada.
              <br /><br />
              Violaciones del Sistema o Bases de Datos: No está permitida
              ninguna acción o uso de dispositivo, software, u otro medio
              tendiente a interferir tanto en las actividades y operatoria
              de Exotics Colombia Autos como en las ofertas, descripciones,
              cuentas o bases de datos. Cualquier intromisión, tentativa o
              actividad violatoria o contraria a las leyes sobre derecho de
              propiedad intelectual y/o a las prohibiciones estipuladas en este
              contrato harán pasible a su responsable de las acciones legales
              pertinentes, y a las sanciones previstas por este acuerdo, así
              como lo hará responsable de indemnizar los daños ocasionados.
              <br /><br />
              Sanciones: Sin perjuicio de otras medidas, Exotics Colombia
              Autos podrá advertir y suspender temporal o inhabilitar
              definitivamente a un Usuario o un aviso en alguno de los
              siguientes casos (a) si tuviera conocimiento que éste quebranta
              alguna ley, o cualquiera de las estipulaciones de los Términos y
              Condiciones Generales y demás políticas del Sitio; (b) incumpliera
              sus compromisos como Usuario; (c) incurriera en conductas o actos
              dolosos o fraudulentos; (d) cualquier información proporcionada
              por el mismo que fuere errónea; (e) Exotics Colombia Autos
              interpreta que las publicaciones u otras acciones pueden ser causa
              de responsabilidad para el Usuario que las anunció, para Exotics
              Colombia Autos  o para los Usuarios. En el caso de la suspensión o
              inhabilitación de un Usuario, todos los bienes que tuviera
              anunciados serán removidos del sistema.
              <br /><br />
              El Sitio puede contener enlaces a otros sitios web lo cual no
              indica que sean propiedad u operados por Exotics Colombia Autos.
              En virtud que Exotics Colombia Autos no tiene control sobre tales
              sitios, no será responsable por los contenidos, materiales,
              acciones y/o servicios prestados por los mismos, ni por daños o
              pérdidas ocasionadas por la utilización de estos, sean causados
              directa o indirectamente. La presencia de enlaces a otros sitios
              web no implica una sociedad, relación, aprobación, respaldo
              de Exotics Colombia Autosa dichos sitios y sus contenidos.
              <br /><br />
              Jurisdicción y Ley Aplicable.Este acuerdo estará regido en todos
              sus puntos por las leyes vigentes en la República de Colombia.
              <br /><br />
              Cualquier controversia derivada del presente acuerdo, su
              existencia, validez, interpretación, alcance o cumplimiento, será
              sometida a las leyes aplicables y a los tribunales competentes de
              la Ciudad de Medellin
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <!-- <div class="financiarContainer py-5 d-lg-none">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2ExoticsMobile text-left">
              TE ASESORAMOS
            </h2>
            <p class="text-left fontLightExotics pExotics">
              Te ayudamos a encontrar el carro de tus sueños
            </p>
          </div>
        </div>
        <div class="financiarForm pt-5">
          <div class="infoPersonalForm">
            <p class="subTitle text-left fontLightExotics">
              INFORMACIÓN PERSONAL
            </p>
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  placeholder="NOMBRE"
                />
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  placeholder="APELLIDO"
                />
              </div>
              <div class="col-12 mt-3">
                <input
                  type="email"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="CORREO ELECTRÓNICO"
                />
              </div>
              <div class="col-12 mt-3">
                <input
                  type="text"
                  class="form-control busquedaAvanzadaInput outlineInput"
                  placeholder="CELULAR"
                />
              </div>
            </div>
          </div>
          <div class="infoVehiculoForm pt-5">
            <div class="row text-right flex-row-reverse">
              <div class="col-12">
                <b-button
                  class="btn btn-primary btnPrimaryExotics fullWidthExotics"
                  href=""
                  >CONTÁCTANOS</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <Footer></Footer>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Recomendation from "@/components/Recomendation.vue";
import LoginForm from "@/components/LoginForm.vue";
import Register from "@/components/Register.vue";

export default {
  name: "Condiciones",
  components: {
    Menu,
    Footer,
    Recomendation,
    LoginForm,
    Register,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
