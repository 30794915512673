<template>
    <div class="fichaTabs mt-5">
        <b-tabs content-class="mt-3">
            <b-tab title="Ficha Técnica" class="tabTitle" active>
                <div class="row fontLightExotics text-left">
                    <div class="col-lg-3 col-6">
                        <div class="marcaDetalle py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Marca</strong> <p>{{ info.marca | Capitalize  }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Modelo</strong> <p>{{ info.modelo | Capitalize  }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Combustible</strong> <p>{{ info.tipo_de_motor | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Transmisión</strong> <p>{{ info.transmision | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Versión</strong> <p>{{ info.version  | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Potencia</strong> <p>{{ info.potencia | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Motor</strong> <p>{{ info.motor | Capitalize }} </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row fontLightExotics text-left">
                </div>
            </b-tab>
            <b-tab title="Adicionales" class="tabTitle">
                <div class="row fontLightExotics text-left">
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Placa</strong> <p>{{ info.placas | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Tipo vehículo</strong> <p>{{ info.tipo_de_vehiculo | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Carrocería</strong> <p>{{ info.carroceria | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Garantía</strong> 
                                
                                <p v-if="info.garantia">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Velocidad Max</strong> <p>{{ info.velocidad_max | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>0/100km</strong> <p>{{ info.kmh100 | Capitalize }}</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Híbrido</strong> 
                                <p v-if="info.hibrido">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="pb-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Eléctrico</strong> 
                                <p v-if="info.electrico">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="py-3">
                            <p class="itemPrincipal mb-0">
                                <strong>Blindaje</strong> 
                                <p v-if="info.blindaje">Si</p>
                                <p v-else>No</p>
                            </p>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Descripción" class="tabTitle">
                <div class="fontLightExotics text-left">
                    <p class="description bodyText pt-3">
                    {{ info.description | Capitalize }}
                    </p>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

export default {
  name: 'FichaTabs',
  props: [
    'info'
  ],
  data (){
      return{
          pill: false,
          hide: false
      }
  },
  methods: {
      showTabs () {
          this.pill=true
        this.hide=true  
    }
  },
  mounted() {
      console.log(this.info)
    // showTabs(this.info) 
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
